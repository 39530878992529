import React from 'react'
import './object.css'

import { ReactComponent as Address } from '../../assets/icons/icon-address.svg'
import { Link } from 'react-router-dom'

const Object = ({header, address, img, id}) => {
  return (
		<Link to={`/item/${id}`}>
			<div className='object-card'>
				<div>
					<img
						src={img}
						alt=''
						width={'100%'}
						height={'231px'}
						style={{ objectFit: 'cover' }}
					/>
				</div>
				<div className='card-footer'>
					<p className='card-price'>от 1100 руб/сутки</p>
					<br />
					<div style={{ display: 'flex', gap: '2px' }}>
						<div className='star'></div>
						<div className='star'></div>
						<div className='star'></div>
						<div className='star'></div>
						<div className='star'></div>
					</div>
					<br />
					<p className='card-name'>Пансионат "Теплые Деньки"</p>
					<br />
					<div style={{ display: 'flex', gap: '5px', color: '#333' }}>
						<Address style={{ minHeight: '18px', minWidth: '18px' }} />
						<p>{address}</p>
					</div>
					{/* <button className='more-btn'>Узнать наличие мест</button> */}
				</div>
			</div>
		</Link>
	)
}

export default Object