import React, { useState } from 'react'
import './filters.css'

import { ReactComponent as Arrow } from '../../assets/icons/icon-arrow.svg'

const Filters = ({onFiltration}) => {
	const districts = [
		'Балашихинский',
		'Бутово',
		'Воскресенский',
		'Дзержинский',
		'Дмитровский',
		'Долгопрудный',
		'Домодедовский',
		'Жуковский',
		'Звенигородский',
		'Зеленоград',
		'Истринский',
		'Каширский',
		'Клинский',
		'Коломенский',
		'Королёв',
		'Красногорский',
		'Ленинский',
		'Лобня',
		'Люберецкий',
		'Можайский',
		'Мытищинский',
		'Наро-Фоминский',
		'Новая Москва',
		'Ногинский',
		'Одинцовский',
		'Орехово-Зуевский',
		'Павлово-Посадский',
		'Подольский',
		'Пушкинский',
		'Раменский',
		'Рузский',
		'Сергиево-Посадский',
		'Серпуховской',
		'Солнечногорский',
		'Троицкий',
		'Химкинский',
		'Чеховский',
		'Шатурский',
		'Щелковский',
	]


	const [sections, setSections] = useState(null)
	const [filters, setFilters] = useState(
		{
			Disease: null,
			Condition: null,
			Location: null,
			District: null,
		},
	)

	const handleOpenSection = (el) => {
		if(sections === el) {
			return setSections(null)
		}
		return setSections(el)
	}

	const handleCloseSection = () => setSections(null)

  return (
		<div
			style={{ position: 'absolute', width: '100%', left: '0' }}
			className='container'
		>
			<div className='filters'>
				<p className='header'>Подобрать пансионат</p>
				<div className='filters-panel'>
					<div>
						<div className='custom-select' onClick={() => handleOpenSection(1)}>
							<span>{filters.Disease ? filters.Disease : 'Заболевание'}</span>
							<Arrow />
						</div>
						{sections === 1 && (
							<div className='selects' onClick={handleCloseSection}>
								<p
									onClick={() =>
										setFilters({ ...filters, Disease: 'Альцгеймер' })
									}
								>
									Альцгеймер
								</p>
								<p
									onClick={() =>
										setFilters({ ...filters, Disease: 'Деменция' })
									}
								>
									Деменция
								</p>
								<p
									onClick={() =>
										setFilters({ ...filters, Disease: 'Онкобольной' })
									}
								>
									Онкобольной
								</p>
								<p
									onClick={() =>
										setFilters({ ...filters, Disease: 'Паркинсон' })
									}
								>
									Паркинсон
								</p>
								<p
									onClick={() =>
										setFilters({ ...filters, Disease: 'Перелом шейки бедра' })
									}
								>
									Перелом шейки бедра
								</p>
								<p
									onClick={() =>
										setFilters({ ...filters, Disease: 'После инсульта' })
									}
								>
									После инсульта
								</p>
								<p
									onClick={() =>
										setFilters({ ...filters, Disease: 'После инфаркта' })
									}
								>
									После инфаркта
								</p>
								<p
									onClick={() =>
										setFilters({ ...filters, Disease: 'Сахарный диабет' })
									}
								>
									Сахарный диабет
								</p>
							</div>
						)}
					</div>
					<div>
						<div className='custom-select' onClick={() => handleOpenSection(2)}>
							<span>
								{filters.Condition ? filters.Condition : 'Физ. состояние'}
							</span>
							<Arrow />
						</div>
						{sections === 2 && (
							<div className='selects' onClick={handleCloseSection}>
								<p
									onClick={() =>
										setFilters({ ...filters, Condition: 'Инвалид-колясочник' })
									}
								>
									Инвалид-колясочник
								</p>
								<p
									onClick={() =>
										setFilters({ ...filters, Condition: 'Лежачий больной' })
									}
								>
									Лежачий больной
								</p>
								<p
									onClick={() =>
										setFilters({ ...filters, Condition: 'Слабовидящий' })
									}
								>
									Слабовидящий
								</p>
								<p
									onClick={() =>
										setFilters({ ...filters, Condition: 'Ходячий' })
									}
								>
									Ходячий
								</p>
							</div>
						)}
					</div>
					<div>
						<div className='custom-select' onClick={() => handleOpenSection(3)}>
							<span>
								{filters.Location ? filters.Location : 'Расположение'}
							</span>
							<Arrow />
						</div>
						{sections === 3 && (
							<div className='selects' onClick={handleCloseSection}>
								<p
									onClick={() => setFilters({ ...filters, Location: 'Москва' })}
								>
									Москва
								</p>
								<p
									onClick={() =>
										setFilters({ ...filters, Location: 'Московская область' })
									}
								>
									Московская область
								</p>
							</div>
						)}
					</div>
					<div>
						<div className='custom-select' onClick={() => handleOpenSection(4)}>
							<span>{filters.District ? filters.District : 'Район'}</span>
							<Arrow />
						</div>
						{sections === 4 && (
							<div className='selects' onClick={handleCloseSection}>
								{
									districts.map(el => {
										return (
											<p
												onClick={() =>
													setFilters({ ...filters, District: el })
												}
											>{el}</p>
										)
									})
								}
							</div>
						)}
					</div>
					<button className='select-btn' onClick={() => onFiltration(filters.District)}>Подобрать</button>
				</div>
			</div>
		</div>
	)
}

export default Filters