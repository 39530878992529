import React, { useState } from 'react'
import './order-call.css'
import axios from 'axios'

const OrderCall = ({onClose}) => {
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
	const [isSended, setIsSended] = useState(false)

    const onSubmit = async () => {
		const BOT_TOKEN = '7754605626:AAHz3hEbyYVraTftNM8lKsTiJtDWr15v5CA' // Токен вашего бота
		const CHAT_ID = '-1002455385258' // ID чата
		const TELEGRAM_API = `https://api.telegram.org/bot${BOT_TOKEN}/sendMessage`

		try {
			await axios.post(TELEGRAM_API, {
				chat_id: CHAT_ID,
				text: `<b>Новая заявка</b>\n➖➖➖➖➖➖➖➖➖\n👤<b>ИМЯ:</b> ${name}\n📞<b>НОМЕР:</b> ${phone}\n`,
				parse_mode: 'HTML',
			})
			setName('')
			setPhone('')
			setIsSended(true)
		} catch (error) {
			console.error('Ошибка при отправке:', error)
			alert('Ошибка отправки сообщения.')
		}
    }
  return (
		<div className='order-call-panel'>
			<div className='order-call-form'>
				<div style={{display: "flex", justifyContent: "end", position: "absolute", width: "100%", left: "0", paddingRight: "25px"}}>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='24'
						height='24'
						fill='gray'
						class='bi bi-x-lg'
						viewBox='0 0 16 16'
                        style={{cursor: "pointer"}}
                        onClick={onClose}
					>
						<path d='M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z' />
					</svg>
				</div>
				<h2>Заказать звонок</h2>
				<input
					type='text'
					placeholder='Имя *'
					value={name}
					onChange={evn => setName(evn.target.value)}
				/>
				<input
					type='text'
					placeholder='Телефон *'
					value={phone}
					onChange={evn => setPhone(evn.target.value)}
				/>
				{isSended && <p style={{color: "green"}}>Спасибо за заявку! Мы скоро с вами свяжемся.</p>}
				<button onClick={onSubmit}>Заказать</button>
			</div>
		</div>
	)
}

export default OrderCall