import React, { useEffect, useState } from 'react'
import Img from '../../assets/home1.jpg'
import homes from '../../pages/home/Home'
import './item.css'
import Home1 from '../../assets/home1.jpg'
import Home2 from '../../assets/home2.jpg'
import Home3 from '../../assets/home3.jpg'
import Home4 from '../../assets/home4.jpg'
import Home5 from '../../assets/home5.jpg'
import Home6 from '../../assets/home6.jpg'
import Home7 from '../../assets/home7.jpg'
import Home8 from '../../assets/home8.jpg'
import Home9 from '../../assets/home9.jpg'
import Home10 from '../../assets/home10.jpg'
import Home11 from '../../assets/home11.jpg'
import Home12 from '../../assets/home12.jpg'
import Home13 from '../../assets/home13.jpg'
import Home14 from '../../assets/home14.jpg'
import Home15 from '../../assets/home15.jpg'
import Home16 from '../../assets/home16.jpg'
import Home17 from '../../assets/home17.jpg'
import Home18 from '../../assets/home18.jpg'
import Home19 from '../../assets/home19.jpg'
import Home20 from '../../assets/home20.jpg'
import Home21 from '../../assets/home21.jpg'
import Home22 from '../../assets/home22.jpg'
import Home23 from '../../assets/home23.jpg'
import Home24 from '../../assets/home24.jpg'
import Home25 from '../../assets/home25.jpg'
import Home26 from '../../assets/home26.jpg'
import Home27 from '../../assets/home27.jpg'
import Home28 from '../../assets/home28.jpg'
import Home29 from '../../assets/home29.jpg'
import Home30 from '../../assets/home30.jpg'
import Home31 from '../../assets/home31.jpg'
import Home32 from '../../assets/home32.jpg'
import Home33 from '../../assets/home33.jpg'
import Home34 from '../../assets/home34.jpg'
import Home35 from '../../assets/home35.jpg'
import Home36 from '../../assets/home36.jpg'
import Home37 from '../../assets/home37.jpg'
import Home38 from '../../assets/home38.jpg'

import Img1_1 from '../../assets/images/1_1.jpg'
import Img1_2 from '../../assets/images/1_2.jpg'
import Img1_3 from '../../assets/images/1_3.jpg'
import Img1_4 from '../../assets/images/1_4.jpg'
import Img2_1 from '../../assets/images/2_1.jpg'
import Img2_2 from '../../assets/images/2_2.jpg'
import Img2_3 from '../../assets/images/2_3.jpg'
import Img2_4 from '../../assets/images/2_4.jpg'
import Img3_1 from '../../assets/images/3_1.jpg'
import Img3_2 from '../../assets/images/3_2.jpg'
import Img3_3 from '../../assets/images/3_3.jpg'
import Img3_4 from '../../assets/images/3_4.jpg'
import Img4_1 from '../../assets/images/4_1.jpg'
import Img4_2 from '../../assets/images/4_2.jpg'
import Img4_3 from '../../assets/images/4_3.jpg'
import Img4_4 from '../../assets/images/4_4.jpg'
import Img5_1 from '../../assets/images/5_1.jpg'
import Img5_2 from '../../assets/images/5_2.jpg'
import Img5_3 from '../../assets/images/5_3.jpg'
import Img6_1 from '../../assets/images/6_1.jpg'
import Img6_2 from '../../assets/images/6_2.jpg'
import Img6_3 from '../../assets/images/6_3.jpg'
import Img6_4 from '../../assets/images/6_4.jpg'
import Img7_1 from '../../assets/images/7_1.jpg'
import Img7_2 from '../../assets/images/7_2.jpg'
import Img7_3 from '../../assets/images/7_3.jpg'
import Img7_4 from '../../assets/images/7_4.jpg'
import Img8_1 from '../../assets/images/8_1.jpg'
import Img8_2 from '../../assets/images/8_2.jpg'
import Img8_3 from '../../assets/images/8_3.jpg'
import Img9_1 from '../../assets/images/9_1.jpg'
import Img9_2 from '../../assets/images/9_2.jpg'
import Img9_3 from '../../assets/images/9_3.jpg'
import Img9_4 from '../../assets/images/9_4.jpg'
import Img9_5 from '../../assets/images/9_5.jpg'
import Img10_1 from '../../assets/images/10_1.jpg'
import Img11_1 from '../../assets/images/11_1.jpg'
import Img11_2 from '../../assets/images/11_2.jpg'
import Img11_3 from '../../assets/images/11_3.jpg'
import Img11_4 from '../../assets/images/11_4.jpg'
import Img12_1 from '../../assets/images/12_1.jpg'
import Img12_2 from '../../assets/images/12_2.jpg'
import Img12_3 from '../../assets/images/12_3.jpg'
import Img13_1 from '../../assets/images/13_1.jpg'
import Img13_2 from '../../assets/images/13_2.jpg'
import Img13_3 from '../../assets/images/13_3.jpg'
import Img13_4 from '../../assets/images/13_4.jpg'
import Img14_1 from '../../assets/images/14_1.jpg'
import Img14_2 from '../../assets/images/14_2.jpg'
import Img14_3 from '../../assets/images/14_3.jpg'
import Img15_1 from '../../assets/images/15_1.jpg'
import Img15_2 from '../../assets/images/15_2.jpg'
import Img15_3 from '../../assets/images/15_3.jpg'
import Img16_1 from '../../assets/images/16_1.jpg'
import Img16_2 from '../../assets/images/16_2.jpg'
import Img16_3 from '../../assets/images/16_3.jpg'
import Img16_4 from '../../assets/images/16_4.jpg'
import Img17_1 from '../../assets/images/17_1.jpg'
import Img17_2 from '../../assets/images/17_2.jpg'
import Img17_3 from '../../assets/images/17_3.jpg'
import Img18_1 from '../../assets/images/18_1.jpg'
import Img18_2 from '../../assets/images/18_2.jpg'
import Img18_3 from '../../assets/images/18_3.jpg'
import Img18_4 from '../../assets/images/18_4.jpg'
import Img19_1 from '../../assets/images/19_1.jpg'
import Img19_2 from '../../assets/images/19_2.jpg'
import Img19_3 from '../../assets/images/19_3.jpg'
import Img20_1 from '../../assets/images/20_1.jpg'
import Img20_2 from '../../assets/images/20_2.jpg'
import Img20_3 from '../../assets/images/20_3.jpg'
import Img20_4 from '../../assets/images/20_4.jpg'
import Img21_1 from '../../assets/images/21_1.jpg'
import Img21_2 from '../../assets/images/21_2.jpg'
import Img21_3 from '../../assets/images/21_3.jpg'
import Img21_4 from '../../assets/images/21_4.jpg'
import Img22_1 from '../../assets/images/22_1.jpg'
import Img22_2 from '../../assets/images/22_2.jpg'
import Img22_3 from '../../assets/images/22_3.jpg'
import Img22_4 from '../../assets/images/22_4.jpg'
import Img23_1 from '../../assets/images/23_1.jpg'
import Img23_2 from '../../assets/images/23_2.jpg'
import Img23_3 from '../../assets/images/23_3.jpg'
import Img24_1 from '../../assets/images/24_1.jpg'
import Img24_2 from '../../assets/images/24_2.jpg'
import Img24_3 from '../../assets/images/24_3.jpg'
import Img24_4 from '../../assets/images/24_4.jpg'
import Img25_1 from '../../assets/images/25_1.jpg'
import Img25_2 from '../../assets/images/25_2.jpg'
import Img25_3 from '../../assets/images/25_3.jpg'
import Img25_4 from '../../assets/images/25_4.jpg'
import Img26_1 from '../../assets/images/26_1.jpg'
import Img26_2 from '../../assets/images/26_2.jpg'
import Img26_3 from '../../assets/images/26_3.jpg'
import Img26_4 from '../../assets/images/26_4.jpg'
import Img27_1 from '../../assets/images/27_1.jpg'
import Img27_2 from '../../assets/images/27_2.jpg'
import Img27_3 from '../../assets/images/27_3.jpg'
import Img27_4 from '../../assets/images/27_4.jpg'
import Img28_1 from '../../assets/images/28_1.jpg'
import Img28_2 from '../../assets/images/28_2.jpg'
import Img28_3 from '../../assets/images/28_3.jpg'
import Img28_4 from '../../assets/images/28_4.jpg'
import Img29_1 from '../../assets/images/29_1.jpg'
import Img29_2 from '../../assets/images/29_2.jpg'
import Img29_3 from '../../assets/images/29_3.jpg'
import Img29_4 from '../../assets/images/29_4.jpg'
import Img30_1 from '../../assets/images/30_1.jpg'
import Img30_2 from '../../assets/images/30_2.jpg'
import Img30_3 from '../../assets/images/30_3.jpg'
import Img30_4 from '../../assets/images/30_4.jpg'
import Img31_1 from '../../assets/images/31_1.jpg'
import Img31_2 from '../../assets/images/31_2.jpg'
import Img31_3 from '../../assets/images/31_3.jpg'
import Img31_4 from '../../assets/images/31_4.jpg'
import Img32_1 from '../../assets/images/32_1.jpg'
import Img32_2 from '../../assets/images/32_2.jpg'
import Img32_3 from '../../assets/images/32_3.jpg'
import Img32_4 from '../../assets/images/32_4.jpg'
import Img33_1 from '../../assets/images/33_1.jpg'
import Img33_2 from '../../assets/images/33_2.jpg'
import Img33_3 from '../../assets/images/33_3.jpg'
import Img33_4 from '../../assets/images/33_4.jpg'
import Img34_1 from '../../assets/images/34_1.jpg'
import Img34_2 from '../../assets/images/34_2.jpg'
import Img34_3 from '../../assets/images/34_3.jpg'
import Img34_4 from '../../assets/images/34_4.jpg'
import Img35_1 from '../../assets/images/35_1.jpg'
import Img35_2 from '../../assets/images/35_2.jpg'
import Img36_1 from '../../assets/images/36_1.jpg'
import Img36_2 from '../../assets/images/36_2.jpg'
import Img36_3 from '../../assets/images/36_3.jpg'
import Img36_4 from '../../assets/images/36_4.jpg'
import Img37_1 from '../../assets/images/37_1.jpg'
import Img37_2 from '../../assets/images/37_2.jpg'
import Img37_3 from '../../assets/images/37_3.jpg'
import Img37_4 from '../../assets/images/37_4.jpg'
import Img38_1 from '../../assets/images/38_1.jpg'
import Img38_2 from '../../assets/images/38_2.jpg'
import Img38_3 from '../../assets/images/38_3.jpg'
import Img38_4 from '../../assets/images/38_4.jpg'
import { ReactComponent as Phone } from '../../assets/icons/phone.svg'
import { useParams } from "react-router"

const Item = () => {
	let params = useParams()
	const [item, setItem] = useState(
		...[
			{
				id: 1,
				header: 'Дом престарелых «Сходня»',
				address: 'Московская область, Химки, мкр. Сходня, улица Черняховского',
				img: Home1,
				map: 'https://yandex.com/map-widget/v1/?ll=37.286573%2C55.945306&mode=search&ol=geo&ouri=ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCggxMDA0ODI1OBKHAdCg0L7RgdGB0LjRjywg0JzQvtGB0LrQvtCy0YHQutCw0Y8g0L7QsdC70LDRgdGC0YwsINCl0LjQvNC60LgsINC80LjQutGA0L7RgNCw0LnQvtC9INCh0YXQvtC00L3Rjywg0YPQu9C40YbQsCDQp9C10YDQvdGP0YXQvtCy0YHQutC-0LPQviIKDWslFUIVBchfQg%2C%2C&z=19.35',
				images: [Home1, Img1_1, Img1_2, Img1_3, Img1_4],
				booked: 47,
				free: 16,
				like: 63,
				praise: 19,
			},
			{
				id: 2,
				header: 'Пансионат «Домодедовская»',
				address:
					'Московская область, Ленинский городской округ, деревня Апаринки',
				img: Home2,
				map: 'https://yandex.com/map-widget/v1/?ll=37.761317%2C55.572266&mode=search&ol=geo&ouri=ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgg1MzA2MjgwMBKEAdCg0L7RgdGB0LjRjywg0JzQvtGB0LrQvtCy0YHQutCw0Y8g0L7QsdC70LDRgdGC0YwsINCb0LXQvdC40L3RgdC60LjQuSDQs9C-0YDQvtC00YHQutC-0Lkg0L7QutGA0YPQsywg0LTQtdGA0LXQstC90Y8g0JDQv9Cw0YDQuNC90LrQuCIKDdEKF0IVw0leQg%2C%2C&z=16.74',
				images: [Home2, Img2_1, Img2_2, Img2_3, Img2_4],
				booked: 38,
				free: 20,
				like: 49,
				praise: 24,
			},
			{
				id: 3,
				header: 'Пансионат на Ильинском шоссе',
				address:
					'Московская область, Красногорск, село Петрово-Дальнее, ул. Тихвинская',
				img: Home3,
				map: 'https://yandex.com/map-widget/v1/?ll=37.188627%2C55.746970&mode=search&ol=geo&ouri=ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgoxNjM1MzQ0MjIwErAB0KDQvtGB0YHQuNGPLCDQnNC-0YHQutC-0LLRgdC60LDRjyDQvtCx0LvQsNGB0YLRjCwg0LPQvtGA0L7QtNGB0LrQvtC5INC-0LrRgNGD0LMg0JrRgNCw0YHQvdC-0LPQvtGA0YHQuiwg0YHQtdC70L4g0J_QtdGC0YDQvtCy0L4t0JTQsNC70YzQvdC10LUsINCi0LjRhdCy0LjQvdGB0LrQsNGPINGD0LvQuNGG0LAiCg0owRRCFen8XkI%2C&z=18.59',
				images: [Home3, Img3_1, Img3_2, Img3_3, Img3_4],
				booked: 62,
				free: 10,
				like: 55,
				praise: 27,
			},
			{
				id: 4,
				header: 'Пансионат для престарелых «Мытищи»',
				address:
					'Московская область, городской округ Мытищи, деревня Ульянково, ул. Окольная',
				img: Home4,
				map: 'https://yandex.com/map-widget/v1/?ll=37.706649%2C56.000996&mode=search&ol=geo&ouri=ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgoxNTg4OTk4Nzg1Ep0B0KDQvtGB0YHQuNGPLCDQnNC-0YHQutC-0LLRgdC60LDRjyDQvtCx0LvQsNGB0YLRjCwg0LPQvtGA0L7QtNGB0LrQvtC5INC-0LrRgNGD0LMg0JzRi9GC0LjRidC4LCDQtNC10YDQtdCy0L3RjyDQo9C70YzRj9C90LrQvtCy0L4sINCe0LrQvtC70YzQvdCw0Y8g0YPQu9C40YbQsCIKDY3TFkIVNQFgQg%2C%2C&z=18.39',
				images: [Home4, Img4_1, Img4_2, Img4_3, Img4_4],
				booked: 50,
				free: 17,
				like: 60,
				praise: 24,
			},
			{
				id: 5,
				header: 'Пансионат для пожилых «Ховрино»',
				address:
					'Московская область, городской округ Мытищи, деревня Ховрино, ул. Бородинская',
				img: Home5,
				map: 'https://yandex.com/map-widget/v1/?ll=37.651075%2C55.930807&mode=search&ol=geo&ouri=ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgoyMTk5MDIxMTc5Ep8B0KDQvtGB0YHQuNGPLCDQnNC-0YHQutC-0LLRgdC60LDRjyDQvtCx0LvQsNGB0YLRjCwg0LPQvtGA0L7QtNGB0LrQvtC5INC-0LrRgNGD0LMg0JzRi9GC0LjRidC4LCDQtNC10YDQtdCy0L3RjyDQpdC-0LLRgNC40L3Qviwg0JHQvtGA0L7QtNC40L3RgdC60LDRjyDRg9C70LjRhtCwIgoNYZoWQhXMuF9C&z=18.05',
				images: [Home5, Img5_1, Img5_2, Img5_3],
				booked: 78,
				free: 20,
				like: 64,
				praise: 29,
			},
			{
				id: 6,
				header: 'Пансионат для пожилых «Теплый стан»',
				address:
					'Москва, деревня Десна, поселение Десеновское, ул.Административная',
				img: Home6,
				map: 'https://yandex.com/map-widget/v1/?ll=37.371051%2C55.521120&mode=search&ol=geo&ouri=ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgoxNjg2ODczNTY3EnvQoNC-0YHRgdC40Y8sINCc0L7RgdC60LLQsCwg0YDQsNC50L7QvSDQotGA0L7QuNGG0LosINC00LXRgNC10LLQvdGPINCU0LXRgdC90LAsINCQ0LTQvNC40L3QuNGB0YLRgNCw0YLQuNCy0L3QsNGPINGD0LvQuNGG0LAiCg3LexVCFQUWXkI%2C&z=18.5',
				images: [Home6, Img6_1, Img6_2, Img6_3, Img6_4],
				booked: 53,
				free: 20,
				like: 50,
				praise: 25,
			},
			{
				id: 7,
				header: 'Пансионат в Медведково',
				address:
					'Московская область, городской округ Мытищи, деревня Пирогово, Фабричный проезд',
				img: Home7,
				map: 'https://yandex.com/map-widget/v1/?ll=37.730796%2C55.980069&mode=search&ol=geo&ouri=ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCggxMDA0NzAwNBKfAdCg0L7RgdGB0LjRjywg0JzQvtGB0LrQvtCy0YHQutCw0Y8g0L7QsdC70LDRgdGC0YwsINCz0L7RgNC-0LTRgdC60L7QuSDQvtC60YDRg9CzINCc0YvRgtC40YnQuCwg0LTQtdGA0LXQstC90Y8g0J_QuNGA0L7Qs9C-0LLQviwg0KTQsNCx0YDQuNGH0L3Ri9C5INC_0YDQvtC10LfQtCIKDVjsFkIVuOtfQg%2C%2C&z=17.68',
				images: [Home7, Img7_1, Img7_2, Img7_3, Img7_4],
				booked: 27,
				free: 12,
				like: 33,
				praise: 17,
			},
			{
				id: 8,
				header: 'Дом престарелых «Щукинская»',
				address:
					'Московская область, Истринский район, деревня Покровское, Покровский бульвар',
				img: Home8,
				map: 'https://yandex.com/map-widget/v1/?ll=36.983923%2C55.811202&mode=search&ol=geo&ouri=ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgoxNTY3NDE5OTQ0Et8B0KDQvtGB0YHQuNGPLCDQnNC-0YHQutC-0LLRgdC60LDRjyDQvtCx0LvQsNGB0YLRjCwg0LzRg9C90LjRhtC40L_QsNC70YzQvdGL0Lkg0L7QutGA0YPQsyDQmNGB0YLRgNCwLCDQtNC10YDQtdCy0L3RjyDQn9C-0LrRgNC-0LLRgdC60L7QtSwg0YLQtdGA0YDQuNGC0L7RgNC40Y8g0JbQmiDQndC-0LLQvtGA0LjQttGB0LrQuNC5LCDQn9C-0LrRgNC-0LLRgdC60LjQuSDQsdGD0LvRjNCy0LDRgCIKDYvvE0IVpD5fQg%2C%2C&z=15.84',
				images: [Home8, Img8_1, Img8_2, Img8_3],
				booked: 57,
				free: 17,
				like: 60,
				praise: 25,
			},
			{
				id: 9,
				header: 'Пансионат Академия долголетия',
				address: 'Одинцовский район горки',
				img: Home9,
				map: 'https://yandex.com/map-widget/v1/?ll=36.910313%2C55.648033&mode=search&ol=geo&ouri=ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgg1MzAwMjM1MhJn0KDQvtGB0YHQuNGPLCDQnNC-0YHQutC-0LLRgdC60LDRjyDQvtCx0LvQsNGB0YLRjCwg0J7QtNC40L3RhtC-0LLRgdC60LjQuSDQs9C-0YDQvtC00YHQutC-0Lkg0L7QutGA0YPQsyIKDWuaE0IVJJFeQg%2C%2C&z=12.66',
				images: [Home9, Img9_1, Img9_2, Img9_3, Img9_4, Img9_5],
				booked: 35,
				free: 10,
				like: 45,
				praise: 39,
			},
			{
				id: 10,
				header: 'Пансионат для пожилых Эдем',
				address: 'Московская область, город Домодедово, Рабочая улица',
				img: Home10,
				district: 'Домодедовский',
				images: [Home10, Img10_1],
				booked: 29,
				free: 20,
				like: 60,
				praise: 15,
			},
			{
				id: 11,
				header: '',
				address: 'ул. Станционная, 20, Королёв, Московская обл., 141060',
				img: Home11,
				district: 'Королёв',
				images: [Home11, Img11_1, Img11_2, Img11_3, Img11_4],
				booked: 40,
				free: 21,
				like: 54,
				praise: 29,
			},
			{
				id: 12,
				header: 'Пансионат Толмачево',
				address: 'Пансионат Толмачево .Раменский район',
				img: Home12,
				district: 'Раменский',
				map: '',
				images: [Home12, Img12_1, Img12_2, Img12_3],
				booked: 35,
				free: 24,
				like: 30,
				praise: 12,
			},
			{
				id: 13,
				header: 'Пансионат Денежниково',
				address: 'Пансионат Денежниково Раменском муниципальном районе',
				img: Home13,
				district: 'Раменский',
				images: [Home13, Img13_1, Img13_2, Img13_3],
				booked: 33,
				free: 19,
				like: 60,
				praise: 29,
			},
			{
				id: 14,
				header: 'Пансионат Забота',
				address: 'Пансионат Забота Г.Мытищи',
				img: Home14,
				district: 'Мытищинский',
				images: [Home14, Img14_1, Img14_2, Img14_3],
				booked: 27,
				free: 30,
				like: 40,
				praise: 19,
			},
			{
				id: 15,
				header: 'Пансионат для пожилых "Рассвет" Дедовск',
				address: 'Пансионат для пожилых "Рассвет" Дедовск',
				img: Home15,
				district: 'Истринский',
				images: [Home15, Img15_1, Img15_2, Img15_3],
				booked: 31,
				free: 22,
				like: 42,
				praise: 16,
			},
			{
				id: 16,
				header: 'Пансионат океан милосердия',
				address: 'Пансионат океан милосердия Люберецкий район',
				img: Home16,
				district: 'Люберецкий',
				images: [Home16, Img16_1, Img16_2, Img16_3, Img16_4],
				booked: 37,
				free: 15,
				like: 49,
				praise: 29,
			},
			{
				id: 17,
				header: 'Пансионат океан милосердия',
				address: 'Московская область, г. Балашиха, дер. Черное, ул. Журавлиная',
				img: Home17,
				district: 'Балашихинский',
				images: [Home17, Img17_1, Img17_2, Img17_3],
				booked: 47,
				free: 7,
				like: 53,
				praise: 32,
			},
			{
				id: 18,
				header: 'Пансионат океан милосердия',
				address:
					'Московская обл., Одинцовский район, р.п. Новоивановское, ул. Амбулаторная, 80',
				img: Home18,
				district: 'Одинцовский',
				images: [Home18, Img18_1, Img18_2, Img18_3, Img18_4],
				booked: 53,
				free: 6,
				like: 53,
				praise: 22,
			},
			{
				id: 19,
				header: 'Пансионат океан милосердия',
				address:
					'Московская обл., Одинцовский район, р.п. Новоивановское, ул. Амбулаторная, 80',
				img: Home19,
				district: 'Одинцовский',
				images: [Home19, Img19_1, Img19_2, Img19_3],
				booked: 47,
				free: 7,
				like: 53,
				praise: 32,
			},
			{
				id: 20,
				header: 'Пансионат океан милосердия',
				address: 'Московская область, Люберецкий район, деревня Часовня, 54',
				img: Home20,
				district: 'Люберецкий',
				images: [Home20, Img20_1, Img20_2, Img20_3, Img20_4],
				booked: 37,
				free: 10,
				like: 34,
				praise: 32,
			},
			{
				id: 21,
				header: 'Пансионат океан милосердия',
				address:
					'Московская обл., Домодедовский район, г. Домодедово, СНТ Искра-Старосьяново, Берёзовая улица, 4',
				img: Home21,
				district: 'Домодедовский',
				images: [Home21, Img21_1, Img21_2, Img21_3, Img21_4],
				booked: 40,
				free: 12,
				like: 34,
				praise: 32,
			},
			{
				id: 22,
				header: 'Пансионат океан милосердия',
				address:
					'Московская область, Красногорский район, д. Ивановское, д. 1, стр. 3',
				img: Home22,
				district: 'Красногорский',
				images: [Home22, Img22_1, Img22_2, Img22_3, Img22_4],
				booked: 26,
				free: 8,
				like: 31,
				praise: 32,
			},
			{
				id: 23,
				header: 'Пансионат океан милосердия',
				address:
					'Московская область, Истринский район, г. Дедовск, Красная улица',
				img: Home23,
				district: 'Истринский',
				images: [Home23, Img23_1, Img23_2, Img23_3],
				booked: 25,
				free: 10,
				like: 36,
				praise: 22,
			},
			{
				id: 24,
				header: 'Пансионат океан милосердия',
				address: 'Московская область, г. Солнечногорск, ул. 1 Мая, 3А',
				img: Home24,
				district: 'Солнечногорский',
				images: [Home24, Img24_1, Img24_2, Img24_3, Img24_4],
				booked: 39,
				free: 5,
				like: 24,
				praise: 12,
			},
			{
				id: 25,
				header: 'Пансионат океан милосердия',
				address:
					'Московская обл., городской округ Королев, мкр. Юбилейный, Институтский проезд 9',
				img: Home25,
				district: 'Пушкинский',
				images: [Home25, Img25_1, Img25_2, Img25_3, Img25_4],
				booked: 49,
				free: 8,
				like: 32,
				praise: 12,
			},
			{
				id: 26,
				header: 'Пансионат океан милосердия',
				address:
					'Московская область, Раменский городской округ, деревня Вялки, 1-я Железнодорожная улица, 30',
				img: Home26,
				district: 'Раменский',
				images: [Home26, Img26_1, Img26_2, Img26_3, Img26_4],
				booked: 49,
				free: 8,
				like: 32,
				praise: 12,
			},
			{
				id: 27,
				header: 'Пансионат океан милосердия',
				address: 'Московская обл., коттеджный посёлок Бронницы, улица Южная',
				img: Home27,
				district: 'Чеховский',
				images: [Home27, Img27_1, Img27_2, Img27_3, Img27_4],
				booked: 29,
				free: 4,
				like: 36,
				praise: 19,
			},
			{
				id: 28,
				header: 'Пансионат океан милосердия',
				address: 'Московская область, город Жуковский, улица Макаревского',
				img: Home28,
				district: 'Филимонковское',
				images: [Home28, Img28_1, Img28_2, Img28_3, Img28_4],
				booked: 21,
				free: 6,
				like: 40,
				praise: 22,
			},
			{
				id: 29,
				header: 'Пансионат океан милосердия',
				address: 'Москва, поселение Филимонковское',
				img: Home29,
				district: 'Филимонковское',
				images: [Home29, Img29_1, Img29_2, Img29_3, Img29_4],
				booked: 21,
				free: 6,
				like: 40,
				praise: 22,
			},
			{
				id: 30,
				header: 'Пансионат океан милосердия',
				address:
					'Московская область, городской округ Пушкино, село Братовщина, ул. Центральная',
				img: Home30,
				district: 'Пушкинский',
				images: [Home30, Img30_1, Img30_2, Img30_3, Img30_4],
				booked: 28,
				free: 8,
				like: 44,
				praise: 22,
			},
			{
				id: 31,
				header: 'Пансионат океан милосердия',
				address:
					'Московская область, Красногорский район, М-9 Балтия, 23-й километр, влд. 3с1',
				img: Home31,
				district: 'Красногорский',
				images: [Home31, Img31_1, Img31_2, Img31_3, Img31_4],
				booked: 32,
				free: 9,
				like: 39,
				praise: 20,
			},
			{
				id: 32,
				header: 'Пансионат океан милосердия',
				address:
					'Московская область, Раменский район, р.п. Ильинский, Коммунистическая ул., д. 123',
				img: Home32,
				district: 'Раменское',
				images: [Home32, Img32_1, Img32_2, Img32_3, Img32_4],
				booked: 25,
				free: 9,
				like: 43,
				praise: 21,
			},
			{
				id: 33,
				header: 'Пансионат океан милосердия',
				address: 'Москва, улица Лескова, 6Б',
				img: Home33,
				district: 'Москва',
				images: [Home33, Img33_1, Img33_2, Img33_3, Img33_4],
				booked: 28,
				free: 10,
				like: 66,
				praise: 24,
			},
			{
				id: 34,
				header: 'Пансионат океан милосердия',
				address:
					'Московская область, Рузский район, д. Шелковка, ул. Мира, д. 93',
				img: Home34,
				district: 'Одинцовский',
				images: [Home34, Img34_1, Img34_2, Img34_3, Img34_4],
				booked: 28,
				free: 7,
				like: 52,
				praise: 29,
			},
			{
				id: 35,
				header: 'Пансионат океан милосердия',
				address:
					'Московская область, Сергиево-Посадский район, Хотьково, Художественный проезд',
				img: Home35,
				district: 'Сергиево-Посадский',
				images: [Home35, Img35_1, Img35_2],
				booked: 25,
				free: 4,
				like: 59,
				praise: 29,
			},
			{
				id: 36,
				header: 'Пансионат океан милосердия',
				address: 'Москва, улица Габричевского, дом 5, корпус 8',
				img: Home36,
				district: 'Щукино',
				images: [Home36, Img36_1, Img36_2, Img36_3, Img36_4],
				booked: 29,
				free: 5,
				like: 63,
				praise: 29,
			},
			{
				id: 37,
				header: 'Пансионат океан милосердия',
				address:
					'Московская область, Истринский район, город Дедовск, у. Сиреневая',
				img: Home37,
				district: 'Истринский',
				images: [Home37, Img37_1, Img37_2, Img37_3, Img37_4],
				booked: 39,
				free: 6,
				like: 43,
				praise: 32,
			},
			{
				id: 38,
				header: 'Пансионат океан милосердия',
				address: 'Московская область, Истринский район, деревня Обушково',
				img: Home38,
				district: 'Истринский',
				images: [Home38, Img38_1, Img38_2, Img38_3, Img38_4],
				booked: 39,
				free: 3,
				like: 53,
				praise: 32,
			},
		].filter(el => el.id == params.id)
	) 
	const [imagesPosition, setImagesPosition] = useState(0)
	const handleLeft = () => {if (imagesPosition != item.images.length - 1) setImagesPosition(imagesPosition + 1 )}
	const handleRight = () => {if(imagesPosition != 0) setImagesPosition(imagesPosition - 1)}
  return (
		<div className='page'>
			<div className='container'>
				<div style={{ width: '100%' }}>
					<p className='item-name'>Пансионат "Теплые Деньки"</p>
					<div className='item_imgs'>
						<button onClick={handleRight} style={{ left: '20px' }}>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='16'
								height='16'
								fill='currentColor'
								class='bi bi-arrow-left'
								viewBox='0 0 16 16'
							>
								<path
									fill-rule='evenodd'
									d='M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8'
								/>
							</svg>
						</button>
						<img
							src={item.images[imagesPosition]}
							style={{ width: '100%', maxWidth: '900px' }}
						/>
						<button onClick={handleLeft} style={{ right: '20px' }}>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='16'
								height='16'
								fill='currentColor'
								class='bi bi-arrow-right'
								viewBox='0 0 16 16'
							>
								<path
									fill-rule='evenodd'
									d='M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8'
								/>
							</svg>
						</button>
					</div>
					<br />
					<div className='item-info-boxes'>
						<div className='item-info-box'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='25'
								height='25'
								fill='var(--main-background)'
								class='bi bi-stars'
								viewBox='0 0 16 16'
							>
								<path d='M7.657 6.247c.11-.33.576-.33.686 0l.645 1.937a2.89 2.89 0 0 0 1.829 1.828l1.936.645c.33.11.33.576 0 .686l-1.937.645a2.89 2.89 0 0 0-1.828 1.829l-.645 1.936a.361.361 0 0 1-.686 0l-.645-1.937a2.89 2.89 0 0 0-1.828-1.828l-1.937-.645a.361.361 0 0 1 0-.686l1.937-.645a2.89 2.89 0 0 0 1.828-1.828zM3.794 1.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387A1.73 1.73 0 0 0 4.593 5.69l-.387 1.162a.217.217 0 0 1-.412 0L3.407 5.69A1.73 1.73 0 0 0 2.31 4.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387A1.73 1.73 0 0 0 3.407 2.31zM10.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.16 1.16 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.16 1.16 0 0 0-.732-.732L9.1 2.137a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732z' />
							</svg>
							<div>
								<p>Забронировали </p>
								<span>{item.booked}</span>
							</div>
						</div>
						<div className='item-info-box'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='23'
								height='23'
								fill='var(--main-background)'
								class='bi bi-bell-fill'
								viewBox='0 0 16 16'
							>
								<path d='M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2m.995-14.901a1 1 0 1 0-1.99 0A5 5 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901' />
							</svg>
							<div>
								<p>Свободных мест </p>
								<span>{item.free}</span>
							</div>
						</div>
						<div className='item-info-box'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								fill='var(--main-background)'
								class='bi bi-fire'
								viewBox='0 0 16 16'
							>
								<path d='M8 16c3.314 0 6-2 6-5.5 0-1.5-.5-4-2.5-6 .25 1.5-1.25 2-1.25 2C11 4 9 .5 6 0c.357 2 .5 4-2 6-1.25 1-2 2.729-2 4.5C2 14 4.686 16 8 16m0-1c-1.657 0-3-1-3-2.75 0-.75.25-2 1.25-3C6.125 10 7 10.5 7 10.5c-.375-1.25.5-3.25 2-3.5-.179 1-.25 2 1 3 .625.5 1 1.364 1 2.25C11 14 9.657 15 8 15' />
							</svg>
							<div>
								<p>Хвалят </p>
								<span>{item.praise}</span>
							</div>
						</div>
						<div className='item-info-box'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='23'
								height='23'
								fill='var(--main-background)'
								class='bi bi-heart-fill'
								viewBox='0 0 16 16'
							>
								<path
									fill-rule='evenodd'
									d='M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314'
								/>
							</svg>
							<div>
								<p>Нравится </p>
								<span>{item.like}</span>
							</div>
						</div>
					</div>
					<br />
					<iframe
						src={item.map}
						style={{
							width: '100%',
							borderRadius: '12px',
							border: 'none',
							maxWidth: '900px',
						}}
						height='400'
						frameborder='1'
						allowfullscreen='true'
					></iframe>
				</div>
				<div className='item-info'>
					<h4>Пансионат "Теплые Деньки"</h4>
					<div style={{ display: 'flex', gap: '2px' }}>
						<div className='star'></div>
						<div className='star'></div>
						<div className='star'></div>
						<div className='star'></div>
						<div className='star'></div>
					</div>
					<div style={{ display: 'flex', gap: '10px' }}>
						<Phone />
						<p style={{ fontWeight: '700' }}>+7 (495) 065-95-25</p>
					</div>
					<p className='item-address'>{item.address}</p>
					{/* <p>Район: Домодедовский</p> */}
					<div className='item-info-footer'>
						<p>от 1100 руб/сутки</p>
						<span>
							* - минимальная стоимость действует для людей, не требующих помощи
							в гигиеническом уходе
						</span>
						<br />
						<br />
						<a href='tel:+74950659525'>
							<button className='item-call-btn'>Связаться с пансионатом</button>
						</a>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Item