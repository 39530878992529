import React from 'react'
import './wrapper-boxs.css'

const WrapperBoxs = () => {
  return (
		<div className='boxes'>
			<div className='box'>
				<span>Интересный досуг и общение</span>
			</div>
			<div className='box'>
				<span>Квалифицированный и доброжелательный персонал</span>
			</div>
			<div className='box'>
				<span>5-разовое диетическое питание</span>
			</div>
			<div className='box'>
				<span>Домашний уют и атмосфера</span>
			</div>
		</div>
	)
}

export default WrapperBoxs