import React, { useEffect, useState } from 'react'
import './home.css'
import Wrapper from '../../components/wrapper/Wrapper'
import Object from '../../components/object/Object'
import Documents from '../../components/documents/Documents'
import Home1 from '../../assets/home1.jpg'
import Home2 from '../../assets/home2.jpg'
import Home3 from '../../assets/home3.jpg'
import Home4 from '../../assets/home4.jpg'
import Home5 from '../../assets/home5.jpg'
import Home6 from '../../assets/home6.jpg'
import Home7 from '../../assets/home7.jpg'
import Home8 from '../../assets/home8.jpg'
import Home9 from '../../assets/home9.jpg'
import Home10 from '../../assets/home10.jpg'
import Home11 from '../../assets/home11.jpg'
import Home12 from '../../assets/home12.jpg'
import Home13 from '../../assets/home13.jpg'
import Home14 from '../../assets/home14.jpg'
import Home15 from '../../assets/home15.jpg'
import Home16 from '../../assets/home16.jpg'
import Home17 from '../../assets/home17.jpg'
import Home18 from '../../assets/home18.jpg'
import Home19 from '../../assets/home19.jpg'
import Home20 from '../../assets/home20.jpg'
import Home21 from '../../assets/home21.jpg'
import Home22 from '../../assets/home22.jpg'
import Home23 from '../../assets/home23.jpg'
import Home24 from '../../assets/home24.jpg'
import Home25 from '../../assets/home25.jpg'
import Home26 from '../../assets/home26.jpg'
import Home27 from '../../assets/home27.jpg'
import Home28 from '../../assets/home28.jpg'
import Home29 from '../../assets/home29.jpg'
import Home30 from '../../assets/home30.jpg'
import Home31 from '../../assets/home31.jpg'
import Home32 from '../../assets/home32.jpg'
import Home33 from '../../assets/home33.jpg'
import Home34 from '../../assets/home34.jpg'
import Home35 from '../../assets/home35.jpg'
import Home36 from '../../assets/home36.jpg'
import Home37 from '../../assets/home37.jpg'
import Home38 from '../../assets/home38.jpg'
import Map from '../../components/map/Map'

const Home = () => {
	const [homes, setHomes] = useState([
		{
			id: 1,
			header: 'Дом престарелых «Сходня»',
			address: 'Московская область, Химки, мкр. Сходня, улица Черняховского',
			img: Home1,
			district: 'Химкинский',
		},
		{
			id: 2,
			header: 'Пансионат «Домодедовская»',
			address:
				'Московская область, Ленинский городской округ, деревня Апаринки',
			img: Home2,
			district: 'Домодедовский',
		},
		{
			id: 3,
			header: 'Пансионат на Ильинском шоссе',
			address:
				'Московская область, Красногорск, село Петрово-Дальнее, ул. Тихвинская',
			img: Home3,
			district: 'Красногорский, Одинцовский',
		},
		{
			id: 4,
			header: 'Пансионат для престарелых «Мытищи»',
			address:
				'Московская область, городской округ Мытищи, деревня Ульянково, ул. Окольная',
			img: Home4,
			district: 'Мытищинский',
		},
		{
			id: 5,
			header: 'Пансионат для пожилых «Ховрино»',
			address:
				'Московская область, городской округ Мытищи, деревня Ховрино, ул. Бородинская',
			img: Home5,
			district: 'Мытищинский',
		},
		{
			id: 6,
			header: 'Пансионат для пожилых «Теплый стан»',
			address:
				'Москва, деревня Десна, поселение Десеновское, ул.Административная',
			img: Home6,
			district: 'Троицкий',
		},
		{
			id: 7,
			header: 'Пансионат в Медведково',
			address:
				'Московская область, городской округ Мытищи, деревня Пирогово, Фабричный проезд',
			img: Home7,
			district: 'Мытищинский',
		},
		{
			id: 8,
			header: 'Дом престарелых «Щукинская»',
			address:
				'Московская область, Истринский район, деревня Покровское, Покровский бульвар',
			img: Home8,
			district: 'Истринский',
		},
		{
			id: 9,
			header: 'Пансионат Академия долголетия',
			address: 'Одинцовский район горки',
			img: Home9,
			district: 'Одинцовский',
		},
		{
			id: 10,
			header: 'Пансионат для пожилых Эдем',
			address: 'Московская область, город Домодедово, Рабочая улица',
			img: Home10,
			district: 'Домодедовский',
		},
		{
			id: 11,
			header: '',
			address: 'ул. Станционная, 20, Королёв, Московская обл., 141060',
			img: Home11,
			district: 'Королёв',
		},
		{
			id: 12,
			header: 'Пансионат Толмачево',
			address: 'Пансионат Толмачево .Раменский район',
			img: Home12,
			district: 'Раменский',
		},
		{
			id: 13,
			header: 'Пансионат Денежниково',
			address: 'Пансионат Денежниково Раменском муниципальном районе',
			img: Home13,
			district: 'Раменский',
		},
		{
			id: 14,
			header: 'Пансионат Забота',
			address: 'Пансионат Забота Г.Мытищи',
			img: Home14,
			district: 'Мытищинский',
		},
		{
			id: 15,
			header: 'Пансионат для пожилых "Рассвет" Дедовск',
			address: 'Пансионат для пожилых "Рассвет" Дедовск',
			img: Home15,
			district: 'Истринский',
		},
		{
			id: 16,
			header: 'Пансионат океан милосердия',
			address: 'Пансионат океан милосердия Люберецкий район',
			img: Home16,
			district: 'Люберецкий',
		},
		{
			id: 17,
			header: 'Пансионат океан милосердия',
			address: 'Московская область, г. Балашиха, дер. Черное, ул. Журавлиная',
			img: Home17,
			district: 'Балашихинский',
		},
		{
			id: 18,
			header: 'Пансионат океан милосердия',
			address:
				'Московская обл., Одинцовский район, р.п. Новоивановское, ул. Амбулаторная, 80',
			img: Home18,
			district: 'Одинцовский',
		},
		{
			id: 19,
			header: 'Пансионат океан милосердия',
			address: 'Московская область, Щелково, ул.Медвежьи Озера',
			img: Home19,
			district: 'Щелковский',
		},
		{
			id: 20,
			header: 'Пансионат океан милосердия',
			address: 'Московская область, Люберецкий район, деревня Часовня, 54',
			img: Home20,
			district: 'Люберецкий',
		},
		{
			id: 21,
			header: 'Пансионат океан милосердия',
			address:
				'Московская обл., Домодедовский район, г. Домодедово, СНТ Искра-Старосьяново, Берёзовая улица, 4',
			img: Home21,
			district: 'Домодедовский',
		},
		{
			id: 22,
			header: 'Пансионат океан милосердия',
			address:
				'Московская область, Красногорский район, д. Ивановское, д. 1, стр. 3',
			img: Home22,
			district: 'Красногорский',
		},
		{
			id: 23,
			header: 'Пансионат океан милосердия',
			address:
				'Московская область, Истринский район, г. Дедовск, Красная улица',
			img: Home23,
			district: 'Истринский',
		},
		{
			id: 24,
			header: 'Пансионат океан милосердия',
			address: 'Московская область, г. Солнечногорск, ул. 1 Мая, 3А',
			img: Home24,
			district: 'Солнечногорский',
		},
		{
			id: 25,
			header: 'Пансионат океан милосердия',
			address:
				'Московская обл., городской округ Королев, мкр. Юбилейный, Институтский проезд 9',
			img: Home25,
			district: 'Пушкинский',
		},
		{
			id: 26,
			header: 'Пансионат океан милосердия',
			address:
				'Московская область, Раменский городской округ, деревня Вялки, 1-я Железнодорожная улица, 30',
			img: Home26,
			district: 'Раменский',
		},
		{
			id: 27,
			header: 'Пансионат океан милосердия',
			address: 'Московская обл., коттеджный посёлок Бронницы, улица Южная',
			img: Home27,
			district: 'Чеховский',
		},
		{
			id: 28,
			header: 'Пансионат океан милосердия',
			address: 'Московская область, город Жуковский, улица Макаревского',
			img: Home28,
			district: 'Жуковский',
		},
		{
			id: 29,
			header: 'Пансионат океан милосердия',
			address: 'Москва, поселение Филимонковское',
			img: Home29,
			district: 'Филимонковское',
		},
		{
			id: 30,
			header: 'Пансионат океан милосердия',
			address:
				'Московская область, городской округ Пушкино, село Братовщина, ул. Центральная',
			img: Home30,
			district: 'Пушкинский',
		},
		{
			id: 31,
			header: 'Пансионат океан милосердия',
			address:
				'Московская область, Красногорский район, М-9 Балтия, 23-й километр, влд. 3с1',
			img: Home31,
			district: 'Красногорский',
		},
		{
			id: 32,
			header: 'Пансионат океан милосердия',
			address:
				'Московская область, Раменский район, р.п. Ильинский, Коммунистическая ул., д. 123',
			img: Home32,
			district: 'Раменское',
		},
		{
			id: 33,
			header: 'Пансионат океан милосердия',
			address: 'Москва, улица Лескова, 6Б',
			img: Home33,
			district: 'Москва',
		},
		{
			id: 34,
			header: 'Пансионат океан милосердия',
			address:
				'Московская область, Рузский район, д. Шелковка, ул. Мира, д. 93',
			img: Home34,
			district: 'Одинцовский',
		},
		{
			id: 35,
			header: 'Пансионат океан милосердия',
			address:
				'Московская область, Сергиево-Посадский район, Хотьково, Художественный проезд',
			img: Home35,
			district: 'Сергиево-Посадский',
		},
		{
			id: 36,
			header: 'Пансионат океан милосердия',
			address: 'Москва, улица Габричевского, дом 5, корпус 8',
			img: Home36,
			district: 'Щукино',
		},
		{
			id: 37,
			header: 'Пансионат океан милосердия',
			address:
				'Московская область, Истринский район, город Дедовск, у. Сиреневая',
			img: Home37,
			district: 'Истринский',
		},
		{
			id: 38,
			header: 'Пансионат океан милосердия',
			address: 'Московская область, Истринский район, деревня Обушково',
			img: Home38,
			district: 'Истринский',
		},
	])
	const allHomes = [
		{
			id: 1,
			header: 'Дом престарелых «Сходня»',
			address: 'Московская область, Химки, мкр. Сходня, улица Черняховского',
			img: Home1,
			district: 'Химкинский',
		},
		{
			id: 2,
			header: 'Пансионат «Домодедовская»',
			address:
				'Московская область, Ленинский городской округ, деревня Апаринки',
			img: Home2,
			district: 'Домодедовский',
		},
		{
			id: 3,
			header: 'Пансионат на Ильинском шоссе',
			address:
				'Московская область, Красногорск, село Петрово-Дальнее, ул. Тихвинская',
			img: Home3,
			district: 'Красногорский, Одинцовский',
		},
		{
			id: 4,
			header: 'Пансионат для престарелых «Мытищи»',
			address:
				'Московская область, городской округ Мытищи, деревня Ульянково, ул. Окольная',
			img: Home4,
			district: 'Мытищинский',
		},
		{
			id: 5,
			header: 'Пансионат для пожилых «Ховрино»',
			address:
				'Московская область, городской округ Мытищи, деревня Ховрино, ул. Бородинская',
			img: Home5,
			district: 'Мытищинский',
		},
		{
			id: 6,
			header: 'Пансионат для пожилых «Теплый стан»',
			address:
				'Москва, деревня Десна, поселение Десеновское, ул.Административная',
			img: Home6,
			district: 'Троицкий',
		},
		{
			id: 7,
			header: 'Пансионат в Медведково',
			address:
				'Московская область, городской округ Мытищи, деревня Пирогово, Фабричный проезд',
			img: Home7,
			district: 'Мытищинский',
		},
		{
			id: 8,
			header: 'Дом престарелых «Щукинская»',
			address:
				'Московская область, Истринский район, деревня Покровское, Покровский бульвар',
			img: Home8,
			district: 'Истринский',
		},
		{
			id: 9,
			header: 'Пансионат Академия долголетия',
			address: 'Одинцовский район горки',
			img: Home9,
			district: 'Одинцовский',
		},
		{
			id: 10,
			header: 'Пансионат для пожилых Эдем',
			address: 'Московская область, город Домодедово, Рабочая улица',
			img: Home10,
			district: 'Домодедовский',
		},
		{
			id: 11,
			header: '',
			address: 'ул. Станционная, 20, Королёв, Московская обл., 141060',
			img: Home11,
			district: 'Королёв',
		},
		{
			id: 12,
			header: 'Пансионат Толмачево',
			address: 'Пансионат Толмачево .Раменский район',
			img: Home12,
			district: 'Раменский',
		},
		{
			id: 13,
			header: 'Пансионат Денежниково',
			address: 'Пансионат Денежниково Раменском муниципальном районе',
			img: Home13,
			district: 'Раменский',
		},
		{
			id: 14,
			header: 'Пансионат Забота',
			address: 'Пансионат Забота Г.Мытищи',
			img: Home14,
			district: 'Мытищинский',
		},
		{
			id: 15,
			header: 'Пансионат для пожилых "Рассвет" Дедовск',
			address: 'Пансионат для пожилых "Рассвет" Дедовск',
			img: Home15,
			district: 'Истринский',
		},
		{
			id: 16,
			header: 'Пансионат океан милосердия',
			address: 'Пансионат океан милосердия Люберецкий район',
			img: Home16,
			district: 'Люберецкий',
		},
		{
			id: 17,
			header: 'Пансионат океан милосердия',
			address: 'Московская область, г. Балашиха, дер. Черное, ул. Журавлиная',
			img: Home17,
			district: 'Балашихинский',
		},
		{
			id: 18,
			header: 'Пансионат океан милосердия',
			address:
				'Московская обл., Одинцовский район, р.п. Новоивановское, ул. Амбулаторная, 80',
			img: Home18,
			district: 'Одинцовский',
		},
		{
			id: 19,
			header: 'Пансионат океан милосердия',
			address:
				'Московская обл., Одинцовский район, р.п. Новоивановское, ул. Амбулаторная, 80',
			img: Home19,
			district: 'Одинцовский',
		},
		{
			id: 20,
			header: 'Пансионат океан милосердия',
			address: 'Московская область, Люберецкий район, деревня Часовня, 54',
			img: Home20,
			district: 'Люберецкий',
		},
		{
			id: 21,
			header: 'Пансионат океан милосердия',
			address:
				'Московская обл., Домодедовский район, г. Домодедово, СНТ Искра-Старосьяново, Берёзовая улица, 4',
			img: Home21,
			district: 'Домодедовский',
		},
		{
			id: 22,
			header: 'Пансионат океан милосердия',
			address:
				'Московская область, Красногорский район, д. Ивановское, д. 1, стр. 3',
			img: Home22,
			district: 'Красногорский',
		},
		{
			id: 23,
			header: 'Пансионат океан милосердия',
			address:
				'Московская область, Истринский район, г. Дедовск, Красная улица',
			img: Home23,
			district: 'Истринский',
		},
		{
			id: 24,
			header: 'Пансионат океан милосердия',
			address: 'Московская область, г. Солнечногорск, ул. 1 Мая, 3А',
			img: Home24,
			district: 'Солнечногорский',
		},
		{
			id: 25,
			header: 'Пансионат океан милосердия',
			address:
				'Московская обл., городской округ Королев, мкр. Юбилейный, Институтский проезд 9',
			img: Home25,
			district: 'Пушкинский',
		},
		{
			id: 26,
			header: 'Пансионат океан милосердия',
			address:
				'Московская область, Раменский городской округ, деревня Вялки, 1-я Железнодорожная улица, 30',
			img: Home26,
			district: 'Раменский',
		},
		{
			id: 27,
			header: 'Пансионат океан милосердия',
			address: 'Московская обл., коттеджный посёлок Бронницы, улица Южная',
			img: Home27,
			district: 'Чеховский',
		},
		{
			id: 28,
			header: 'Пансионат океан милосердия',
			address: 'Московская область, город Жуковский, улица Макаревского',
			img: Home28,
			district: 'Жуковский',
		},
		{
			id: 29,
			header: 'Пансионат океан милосердия',
			address: 'Москва, поселение Филимонковское',
			img: Home29,
			district: 'Филимонковское',
		},
		{
			id: 30,
			header: 'Пансионат океан милосердия',
			address:
				'Московская область, городской округ Пушкино, село Братовщина, ул. Центральная',
			img: Home30,
			district: 'Пушкинский',
		},
		{
			id: 31,
			header: 'Пансионат океан милосердия',
			address:
				'Московская область, Красногорский район, М-9 Балтия, 23-й километр, влд. 3с1',
			img: Home31,
			district: 'Красногорский',
		},
		{
			id: 32,
			header: 'Пансионат океан милосердия',
			address:
				'Московская область, Раменский район, р.п. Ильинский, Коммунистическая ул., д. 123',
			img: Home32,
			district: 'Раменское',
		},
		{
			id: 33,
			header: 'Пансионат океан милосердия',
			address: 'Москва, улица Лескова, 6Б',
			img: Home33,
			district: 'Москва',
		},
		{
			id: 34,
			header: 'Пансионат океан милосердия',
			address:
				'Московская область, Рузский район, д. Шелковка, ул. Мира, д. 93',
			img: Home34,
			district: 'Одинцовский',
		},
		{
			id: 35,
			header: 'Пансионат океан милосердия',
			address:
				'Московская область, Сергиево-Посадский район, Хотьково, Художественный проезд',
			img: Home35,
			district: 'Сергиево-Посадский',
		},
		{
			id: 36,
			header: 'Пансионат океан милосердия',
			address: 'Москва, улица Габричевского, дом 5, корпус 8',
			img: Home36,
			district: 'Щукино',
		},
		{
			id: 37,
			header: 'Пансионат океан милосердия',
			address:
				'Московская область, Истринский район, город Дедовск, у. Сиреневая',
			img: Home37,
			district: 'Истринский',
		},
		{
			id: 38,
			header: 'Пансионат океан милосердия',
			address: 'Московская область, Истринский район, деревня Обушково',
			img: Home38,
			district: 'Истринский',
		},
	]
	const filterHomes = district => {
		setHomes(allHomes.filter(e => e.district.includes(district)))
	}
  return (
		<div className='home-page'>
			<Wrapper onFiltration={district => filterHomes(district)} />
			<div className='container'>
				<h1>Популярные объекты</h1>
				<div className='cards'>
					{homes.map(el => {
						return (
							<Object
								header={el.header}
								address={el.address}
								id={el.id}
								img={el.img}
							/>
						)
					})}
				</div>
				<br />
				<br />
				<Map />
				<Documents />
			</div>
		</div>
	)
}

export default Home