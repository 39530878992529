import React from 'react'
import './call.css'

import { ReactComponent as Phone } from '../../assets/icons/phone-light.svg'

const Call = () => {
  return (
		<a href='tel:+74950659525'>
			<div className='call-panel'>
				<div className='call-icon'>
					<Phone />
				</div>
				<span>Поможем подобрать</span>
			</div>
		</a>
	)
}

export default Call