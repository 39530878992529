import React from 'react'

const Contact = () => {
  return (
		<div style={{ marginTop: '64px' }}>
			<div className='container'>
				<h1>Наши контакты</h1>
				<br />
				<p className='contact-phone'>+7 (495) 065-95-25</p>
				<p className='contact-phone'>+7 (800) 755-77-57</p>
				<br />
				<span>Бесплатная горячая линия</span>
				<br />
				<div>
					<iframe
						src='https://yandex.com/map-widget/v1/?ll=37.630078%2C55.718485&mode=search&oid=24266810044&ol=biz&sctx=ZAAAAAgBEAAaKAoSCfFmDd5XtSVAEXmxMEROl0hAEhIJhpDz%2Fj8aXkAR1gEQd%2FVqSEAiBgABAgMEBSgKOABA%2Ba0HSAFiFnJlbGV2X3VzZV9wcmljZV9obnN3PTFiJ21pZGRsZV93aXpleHRyYT1jb2xsZWN0aW9uc19lbWJlZGRpbmc9MWIhbWlkZGxlX3dpemV4dHJhPWhuc3dfZW1iZWRkaW5ncz0xYidtaWRkbGVfd2l6ZXh0cmE9aG5zd19wcmljZV9lbWJlZGRpbmdzPTFiIGJ1c2luZXNzX2ZpbHRlcl9obnN3X2luX2FkdmVydD0xYhxyZWxldl9oYXJkX3J1YnJpY19yZXN1bHRzPTEwagJhbZ0BzczMPaABAKgBAL0Biyz4aMIBBseD%2BcyyBoICYjMt0Lkg0J%2FQsNCy0LvQvtCy0YHQutC40Lkg0L%2FQtdGA0LXRg9C70L7Quiwg0LQuIDE0LCDQnNC%2B0YHQutCy0LAsIDExNTA5MyAsINCe0J7QniDQodCw0LvRg9GB0LrQvtGAigIAkgIDMjEzmgIMZGVza3RvcC1tYXBz&sll=37.630078%2C55.718485&sspn=0.007822%2C0.002714&text=3-%D0%B9%20%D0%9F%D0%B0%D0%B2%D0%BB%D0%BE%D0%B2%D1%81%D0%BA%D0%B8%D0%B9%20%D0%BF%D0%B5%D1%80%D0%B5%D1%83%D0%BB%D0%BE%D0%BA%2C%20%D0%B4.%2014%2C%20%D0%9C%D0%BE%D1%81%D0%BA%D0%B2%D0%B0%2C%20115093%20%2C%20%D0%9E%D0%9E%D0%9E%20%D0%A1%D0%B0%D0%BB%D1%83%D1%81%D0%BA%D0%BE%D1%80&z=17.91'
						width='100%'
						height='500'
						frameborder='1'
						allowfullscreen='true'
						style={{ borderRadius: '12px', border: 'none' }}
						// style='position:relative;'
					></iframe>
				</div>
			</div>
		</div>
	)
}

export default Contact