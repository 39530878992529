import React from 'react'
import './articles.css'
import Art1 from '../../assets/art1.jpg'
import Art2 from '../../assets/art2.jpg'
import Art3 from '../../assets/art3.jpg'
import Art4 from '../../assets/art4.jpg'
import Art5 from '../../assets/art5.jpg'
import Art6 from '../../assets/art6.jpg'

const Articles = () => {
  return (
		<div className='articles-page'>
			<div className='container'>
				<h1>Статьи</h1>
				<div className='articles'>
					<div>
						<div className='article-header'>
							<img src={Art4} alt='article' width={'100%'} height={'250px'} />
							<div className='article-text'>
								<h4>Как оформить родственника в дом престарелых</h4>
								<p>
									Пожилые люди часто не могут самостоятельно справиться с этим и
									им требуется помощь законного представителя – родственника,
									опекуна. Чтобы претендовать на получение направления, нужно
									обратиться в службу социального обслуживания населения по
									месту жительства пенсионера, где подробно объяснят план
									дальнейших действий.
								</p>
							</div>
						</div>
					</div>
					<div>
						<div className='article-header'>
							<img src={Art3} alt='article' width={'100%'} height={'250px'} />
							<div className='article-text'>
								<h4>Старческое слабоумие – признаки и лечение</h4>
								<p>
									С возрастом в головном мозге начинают происходить
									дегенеративные изменения, сопровождающиеся когнитивными
									нарушениями, снижением умственной активности. Слабоумие
									является признаком выраженной деменции.
								</p>
							</div>
						</div>
					</div>
					<div>
						<div className='article-header'>
							<img src={Art6} alt='article' width={'100%'} height={'250px'} />
							<div className='article-text'>
								<h4>Как устроить в дом престарелых за пенсию</h4>
								<p>
									В пожилом и преклонном возрасте многие люди теряют навыки
									самообслуживания, из-за тяжелых хронических заболеваний им
									требуется медицинское наблюдение и специальный уход. Отдельную
									группу составляют маломобильные пенсионеры, а также лица,
									страдающие деменцией, психическими расстройствами
								</p>
							</div>
						</div>
					</div>
					<div>
						<div className='article-header'>
							<img src={Art5} alt='article' width={'100%'} height={'250px'} />
							<div className='article-text'>
								<h4>Телефон для пожилых людей</h4>
								<p>
									Всё больше пожилых людей успешно осваивает мобильные телефоны.
									Согласно статистике, представленной компанией Билайн, более
									половины людей старше 60 лет пользуется смартфонами.
									Представители этой возрастной категории используют гаджеты для
									поиска информации, связанной с темой здоровья, а также для
									общения с родственниками и друзьями.
								</p>
							</div>
						</div>
					</div>
					<div>
						<div className='article-header'>
							<img src={Art2} alt='article' width={'100%'} height={'250px'} />
							<div className='article-text'>
								<h4>Как живут в доме престарелых</h4>
								<p>
									Количество людей преклонного возраста с каждым годом
									увеличивается. После 75-80 лет им сложно ухаживать за собой и
									домом, выполнять повседневные задачи, ходить за покупками,
									готовить еду, убирать в помещении. Многие люди пожилого
									возраста страдают тяжелыми хроническими заболеваниями,
									испытывают сложности при передвижении, нуждаются в постоянном
									уходе и медицинском наблюдении.
								</p>
							</div>
						</div>
					</div>
					<div>
						<div className='article-header'>
							<img src={Art1} alt='article' width={'100%'} height={'250px'} />
							<div className='article-text'>
								<h4>Мифы о домах престарелых</h4>
								<p>
									У некоторых людей до сих пор сохранились представления о домах
									престарелых, как об учреждениях с казённой обстановкой, низким
									уровнем обслуживания. Поэтому считается стыдно поместить
									пожилого родственника на проживание в пансионат, даже, если у
									детей и внуков нет возможности ухаживать за стариками.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Articles