import React from 'react'

const Documents = () => {
  return (
		<div className='documents'>
			<div>
				<img
					src='https://pansionaty-pozhilyh.ru/webp/assets/template/pansionaty-pozhilyh/img/copyright/1.png.webp'
					alt=''
				/>
				<span>
					Департамент труда и социальной защиты населения города москвы
				</span>
			</div>
			<div>
				<img
					src='https://pansionaty-pozhilyh.ru/webp/assets/template/pansionaty-pozhilyh/img/copyright/2.png.webp'
					alt=''
				/>
				<span>
					Федеральная служба по надзору в сфере защиты прав потребителей и
					благополучия человека
				</span>
			</div>
			<div>
				<img
					src='https://pansionaty-pozhilyh.ru/webp/assets/template/pansionaty-pozhilyh/img/copyright/3.png.webp'
					alt=''
				/>
				<span>Департамент здравохранения города москвы</span>
			</div>
		</div>
	)
}

export default Documents