import React from 'react'
import Re1 from '../../assets/re1.jpg'
import Re5 from '../../assets/re5.jpg'
import Re6 from '../../assets/re6.jpg'
import Re7 from '../../assets/re7.jpg'
import Re8 from '../../assets/re8.png'
import Re9 from '../../assets/re9.jpg'
import Re10 from '../../assets/re10.jpg'

const Reabilitation = () => {
  return (
		<div style={{ marginTop: '40px' }}>
			<div className='container'>
				<h1 style={{marginBottom: '40px'}}>Реабилитация и услуги</h1>
				<div className='articles'>
					<div>
						<div className='article-header'>
							<img
								src={Re1}
								alt='article'
								width={'100%'}
								height={'250px'}
								style={{ objectFit: 'cover' }}
							/>
							<div className='article-text'>
								<h4>Реабилитация после инсульта</h4>
								<p>
									Подберем индивидуальный комплекс для восстановления
									кровоснабжения, мышечного тонуса, усиления регенерации
								</p>
							</div>
						</div>
					</div>
					<div>
						<div className='article-header'>
							<img
								src={
									'https://zhemchuzhina.com.ua/wp-content/uploads/2018/10/palliativnaya_pomoshch_na_domu_700h400.jpg'
								}
								alt='article'
								width={'100%'}
								height={'250px'}
								style={{ objectFit: 'cover' }}
							/>
							<div className='article-text'>
								<h4>Паллиативный уход</h4>
								<p>
									Услуги по уходу за паллиативными больными: бытовая помощь,
									психологическая поддержка, контроль состояния здоровья.
								</p>
							</div>
						</div>
					</div>
					<div>
						<div className='article-header'>
							<img
								src={
									'https://m-y.ru/wp-content/webp-express/webp-images/uploads/2023/11/0010-1-1024x683.jpg.webp'
								}
								alt='article'
								width={'100%'}
								height={'250px'}
								style={{ objectFit: 'cover' }}
							/>
							<div className='article-text'>
								<h4>Реабилитация после переломов</h4>
								<p>
									Поможем восстановить функции конечностей, укрепить мышцы и
									связки, улучшить кровообращение.
								</p>
							</div>
						</div>
					</div>
					<div>
						<div className='article-header'>
							<img
								src={Re5}
								alt='article'
								width={'100%'}
								height={'250px'}
								style={{ objectFit: 'cover' }}
							/>
							<div className='article-text'>
								<h4>Реабилитация после операций</h4>
								<p>
									Подберем индивидуальный комплекс для восстановления после
									операций любой сложности.
								</p>
							</div>
						</div>
					</div>
					<div>
						<div className='article-header'>
							<img
								src={Re6}
								alt='article'
								width={'100%'}
								height={'250px'}
								style={{ objectFit: 'cover' }}
							/>
							<div className='article-text'>
								<h4>Патронаж пожилых людей</h4>
								<p>
									Услуги патронажа над пожилыми людьми и инвалидами: социальный
									и бытовой уход в пансионате.
								</p>
							</div>
						</div>
					</div>
					<div>
						<div className='article-header'>
							<img
								src={Re7}
								alt='article'
								width={'100%'}
								height={'250px'}
								style={{ objectFit: 'cover' }}
							/>
							<div className='article-text'>
								<h4>Уход за больными с деменцией</h4>
								<p>
									Услуги по уходу за больными с деменцией: психологическая
									поддержка, контроль приема лекарств.
								</p>
							</div>
						</div>
					</div>
					<div>
						<div className='article-header'>
							<img
								src={Re8}
								alt='article'
								width={'100%'}
								height={'250px'}
								style={{ objectFit: 'cover' }}
							/>
							<div className='article-text'>
								<h4>Уход за больными с онкологией</h4>
								<p>
									Услуги по уходу за больными с онкологией: психологическая
									поддержка, контроль приема лекарств.
								</p>
							</div>
						</div>
					</div>
					<div>
						<div className='article-header'>
							<img src={Re9} alt='article' width={'100%'} height={'250px'} style={{objectFit: 'cover'}}/>
							<div className='article-text'>
								<h4>Уход за больными с инфарктом</h4>
								<p>
									Услуги по уходу за больными с инфарктом: психологическая
									поддержка, контроль приема лекарств.
								</p>
							</div>
						</div>
					</div>
					<div>
						<div className='article-header'>
							<img src={Re10} alt='article' width={'100%'} height={'250px'} style={{objectFit: 'cover'}}/>
							<div className='article-text'>
								<h4>Уход за больными с инсультом</h4>
								<p>
									Услуги по уходу за больными с инсультом: психологическая
									поддержка, контроль приема лекарств.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Reabilitation