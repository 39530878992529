import React from 'react'
import Img from '../../assets/gallery_img1.jpg'
import Img2 from '../../assets/gallery_img2.jpg'
import Img3 from '../../assets/gallery_img3.jpg'
import Img4 from '../../assets/gallery_img4.jpg'
import Img5 from '../../assets/gallery_img5.jpg'
import Img6 from '../../assets/gallery_img6.jpg'
import Img7 from '../../assets/gallery_img7.jpg'
import Img8 from '../../assets/gallery_img8.jpg'
import Img9 from '../../assets/gallery_img9.jpg'
import Img10 from '../../assets/gallery_img10.jpg'
import Img11 from '../../assets/gallery_img11.jpg'
import Img12 from '../../assets/gallery_img12.jpg'
import Img13 from '../../assets/gallery_img13.jpg'
import Img14 from '../../assets/gallery_img14.jpg'
import Img15 from '../../assets/gallery_img15.jpg'
import './gallery.css'

const Gallery = () => {
  return (
		<div className='gallery-page'>
			<div className='container'>
				<h1>Фото из наших пансионатов</h1>
				<div className='gallery'>
					<img src={Img} height={'190px'} width={'100%'} />
					<img src={Img2} height={'190px'} width={'100%'} />
					<img src={Img3} height={'190px'} width={'100%'} />
					<img src={Img4} height={'190px'} width={'100%'} />
					<img src={Img5} height={'190px'} width={'100%'} />
					<img src={Img6} height={'190px'} width={'100%'} />
					<img src={Img7} height={'190px'} width={'100%'} />
					<img src={Img8} height={'190px'} width={'100%'} />
					<img src={Img9} height={'190px'} width={'100%'} />
					<img src={Img10} height={'190px'} width={'100%'} />
					<img src={Img11} height={'190px'} width={'100%'} />
					<img src={Img12} height={'190px'} width={'100%'} />
					<img src={Img13} height={'190px'} width={'100%'} />
					<img src={Img14} height={'190px'} width={'100%'} />
					<img src={Img15} height={'190px'} width={'100%'} />
				</div>
			</div>
		</div>
	)
}

export default Gallery