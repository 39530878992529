import React from 'react'
import Map from '../../components/map/Map'

const MapPage = () => {
  return (
    <div className='container' style={{ marginTop: '50px' }}>
        <Map />
    </div>
  )
}

export default MapPage