import React from 'react'

const About = () => {
  return (
		<div style={{ marginTop: '64px' }}>
			<div className='container'>
				<h1>
					О сети пансионатов{' '}
					<span style={{ color: 'var(--main-background)' }}>«ТЕПЛЫЕ ДЕНЬКИ»</span>
				</h1>
				<br />
				<p>
					Специализированные заведения предлагают не просто места для
					проживания, но и возможность хорошо отдохнуть, поправить здоровье или
					восстановиться после травм, тяжелых болезней. Здесь также работают с
					лежачими больными, помогают тем, кто уже не может заботиться о себе
					самостоятельно, обеспечивают поддержание гигиены, чистоты, опрятного
					внешнего вида. Сотрудники помогут вовремя принять лекарства,
					сопроводят на прогулку на свежем воздухе. Благодаря комплексному
					подходу каждый пожилой человек, попавший в пансионат, чувствует себя
					комфортно, окруженный вниманием и заботой.
				</p>
				<br />
				<p>
					«ТЕПЛЫЕ ДЕНЬКИ» - это сеть пансионатов для пожилых людей и инвалидов.
					Каждое из заведений сети работает по европейской модели. Наши клиенты
					преклонного возраста проживают в небольших и уютных коттеджах,
					расположенных в разных районах Москвы и Подмосковья. Здесь им
					предоставляется профессиональный и круглосуточный уход, медицинское
					обслуживание, поддержка доброжелательных сотрудников и занятия для
					сохранения физических навыков и когнитивных функций.
				</p>
				<br />
				<div>
					<h3>Наша миссия</h3>
					<br />
					<ul>
						<li>Создать комфортные условия для жизни</li>
						<li>Сделать старость спокойной и счастливой</li>
						<li>Замедлить развитие хронических болезней</li>
						<li>Помочь в социализации</li>
						<li>Сделать услугу доступной каждому пожилому человеку</li>
						<li>Обеспечить необходимым медицинским уходом и лечением</li>
					</ul>
				</div>
			</div>
		</div>
	)
}

export default About