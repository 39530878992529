import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/header/Header';
import Call from './components/call/Call';
import Footer from './components/footer/Footer';
import Home from './pages/home/Home';
import Item from './pages/item/Item';
import About from './pages/about/About';
import Contact from './pages/contact/Contact';
import Gallery from './pages/gallery/Gallery';
import Reviews from './pages/reviews/Reviews';
import Menu from './components/menu/Menu';
import { useState } from 'react';
import OrderCall from './components/order-call/OrderCall';
import MapPage from './pages/map/MapPage';
import Prices from './pages/prices/Prices';
import Articles from './pages/articles/Articles';
import Reabilitation from './pages/reabilitation/Reabilitation';

function App() {
  const [isActive, setIsActive] = useState(false)
  const [isOpenedCallOrder, setIsOpenedCallOrder] = useState(false)
  const handleActive = () => setIsActive(!isActive)
  const handleActiveCallOrder = () => setIsOpenedCallOrder(!isOpenedCallOrder)
  return (
		<>
			<Router>
				<Header onOpen={handleActive} onOpenCallOrder={handleActiveCallOrder} />
				<Call />
				{isOpenedCallOrder && <OrderCall onClose={handleActiveCallOrder} />}
				<Menu isActive={isActive} onClose={handleActive} onOpenCallOrder={handleActiveCallOrder}/>
				<Routes>
					<Route path='/*' element={<Home />} />
					<Route path='/item/:id' element={<Item />} />
					<Route path='/about' element={<About />} />
					<Route path='/contact' element={<Contact />} />
					<Route path='/gallery' element={<Gallery />} />
					<Route path='/reviews' element={<Reviews />} />
					<Route path='/map' element={<MapPage />} />
					<Route path='/prices' element={<Prices />} />
					<Route path='/articles' element={<Articles />} />
					<Route path='/reabilitation' element={<Reabilitation />} />
				</Routes>
				<Footer />
			</Router>
		</>
	)
}

export default App;
