import React from 'react'
import './reviews.css'

const Reviews = () => {
  return (
		<div className='reviews-page'>
			<div className='container'>
				<h1>Отзывы о нас</h1>
				<div className='reviews'>
					<div className='review'>
						<p>Наталия</p>
						<div>
							<div className='star'></div>
							<div className='star'></div>
							<div className='star'></div>
							<div className='star'></div>
							<div className='star'></div>
						</div>
						<span>Всё на очень высоком уровне, рекомендуем !!</span>
					</div>
					<div className='review'>
						<p>Анна</p>
						<div>
							<div className='star'></div>
							<div className='star'></div>
							<div className='star'></div>
							<div className='star'></div>
							<div className='star'></div>
						</div>
						<span>Чистый воздух, уютные номера и вкусная еда</span>
					</div>
					<div className='review'>
						<p>Алексей Кузнецов</p>
						<div>
							<div className='star'></div>
							<div className='star'></div>
							<div className='star'></div>
							<div className='star'></div>
							<div className='star'></div>
						</div>
						<span>Все супер! удобные номера</span>
					</div>
					<div className='review'>
						<p>Мария Романова</p>
						<div>
							<div className='star'></div>
							<div className='star'></div>
							<div className='star'></div>
							<div className='star'></div>
							<div className='star'></div>
						</div>
						<span>Очень понравился сервис Персонал отзывчивый</span>
					</div>
					<div className='review'>
						<p>Дмитрий</p>
						<div>
							<div className='star'></div>
							<div className='star'></div>
							<div className='star'></div>
							<div className='star'></div>
							<div className='star'></div>
						</div>
						<span>
							Прекрасное место для тех, кто любит тишину и горный воздух.
						</span>
					</div>
					<div className='review'>
						<p>Артем Егоров</p>
						<div>
							<div className='star'></div>
							<div className='star'></div>
							<div className='star'></div>
							<div className='star'></div>
							<div className='star'></div>
						</div>
						<span>Обязательно приедем снова!</span>
					</div>
					<div className='review'>
						<p>Максим Руденко</p>
						<div>
							<div className='star'></div>
							<div className='star'></div>
							<div className='star'></div>
							<div className='star'></div>
							<div className='star'></div>
						</div>
						<span>Для любителей природы – просто находка!</span>
					</div>
					<div className='review'>
						<p>Марина Зайцева</p>
						<div>
							<div className='star'></div>
							<div className='star'></div>
							<div className='star'></div>
							<div className='star'></div>
							<div className='star'></div>
						</div>
						<span>Понравились лечебные процедуры, внимательный персонал.</span>
					</div>
					<div className='review'>
						<p>Александр Тихонов</p>
						<div>
							<div className='star'></div>
							<div className='star'></div>
							<div className='star'></div>
							<div className='star'></div>
							<div className='star'></div>
						</div>
						<span>
							Чистые, уютные дома, внимательный персонал. Территория красивая
						</span>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Reviews