import React from 'react'
import './prices.css'
import { FaCircleCheck } from 'react-icons/fa6'
import { FaHandHoldingHeart } from 'react-icons/fa6'
import { FaHandHoldingMedical } from 'react-icons/fa6'
import { FaShieldHeart } from 'react-icons/fa6'

const Prices = () => {
  return (
		<div className='prices-page'>
			<div className='container'>
				<h1>Цены</h1>
				<div className='prices-block'>
					<div>
						<FaHandHoldingHeart className='header-icon' />
						<h3>Тариф «Пожилой человек»</h3>
						<p className='price-info'>
							Включает в себя проживание пожилых способных к самообслуживанию
							людей, полностью или частично сохранившие память.{' '}
						</p>
						<div
							style={{
								display: 'grid',
								gap: '10px',
								marginTop: '15px',
								textAlign: 'start',
								width: '250px',
							}}
						>
							<div className='check-panel'>
								<FaCircleCheck className='check-icon' />
								<span>Помощь в бытовых просьбах </span>
							</div>
							<div className='check-panel'>
								<FaCircleCheck className='check-icon' />
								<span>Регулярный осмотр у врача </span>
							</div>
							<div className='check-panel'>
								<FaCircleCheck className='check-icon' />
								<span>Пятиразовое питание </span>
							</div>
							<div className='check-panel'>
								<FaCircleCheck className='check-icon' />
								<span>Организация досуга </span>
							</div>
						</div>
						<p className='block-price'>от 1050 руб/сутки</p>
					</div>
					<div>
						<FaHandHoldingMedical className='header-icon' />
						<h3>Тариф «Больной деменцией» </h3>
						<p className='price-info'>
							Включает в себя проживание для пожилых людей с деменцией,
							нуждающихся в дополнительном уходе и внимании к поддержанию
							когнитивных функций.
						</p>
						<div
							style={{
								display: 'grid',
								gap: '10px',
								marginTop: '15px',
								textAlign: 'start',
								width: '250px',
							}}
						>
							<div className='check-panel'>
								<FaCircleCheck className='check-icon' />
								<span>Занятия для памяти </span>
							</div>
							<div className='check-panel'>
								<FaCircleCheck className='check-icon' />
								<span>Контроль приема лекарств </span>
							</div>
							<div className='check-panel'>
								<FaCircleCheck className='check-icon' />
								<span>Помощь в быту</span>
							</div>
							<div className='check-panel'>
								<FaCircleCheck className='check-icon' />
								<span>Социальная реабилитация </span>
							</div>
						</div>
						<p className='block-price'>от 1150 руб/сутки</p>
					</div>
					<div>
						<FaShieldHeart className='header-icon' />
						<h3>Тариф «Лежачий больной» </h3>
						<p className='price-info'>
							Включает в себя уход за пожилыми людьми после переломов, инсульта,
							за тяжелобольными стариками и теми, кто уже не может
							передвигаться.
						</p>
						<div
							style={{
								display: 'grid',
								gap: '10px',
								marginTop: '15px',
								textAlign: 'start',
								width: '250px',
							}}
						>
							<div className='check-panel'>
								<FaCircleCheck className='check-icon' />
								<span>Особый рацион питания </span>
							</div>
							<div className='check-panel'>
								<FaCircleCheck className='check-icon' />
								<span>Функциональные кровати </span>
							</div>
							<div className='check-panel'>
								<FaCircleCheck className='check-icon' />
								<span>
									Возможность реабилитации по индивидуальной программе{' '}
								</span>
							</div>
						</div>
						<p className='block-price'>от 1350 руб/сутки</p>
					</div>
				</div>
				<br />
				<div>
					<h1 className='price-headers'>Что включено в тариф:</h1>
					<ul className='price-punkts'>
						<li>Комфортное размещение в номере выбранного типа</li>
						<li>Пятиразовое питание, адаптированное под потребности клиента</li>
						<li>Постоянный медицинский контроль</li>
						<li>Регулярные осмотры у врача-терапевта</li>
						<li>Соблюдение назначений врача, контроль за приемом лекарств</li>
						<li>Психологическая поддержка и помощь в социальной адаптации</li>
						<li>
							Предоставление в пользование средств для передвижения: инвалидных
							колясок, ходунков, костылей
						</li>
						<li>Полный бытовой и гигиенический уход</li>
						<li>Организация досуга, культурных мероприятий</li>
						<li>Социальная активность</li>
						<li>Соблюдение правильного распорядка дня</li>
						<li>
							Регулярная влажная уборка всех помещений, проветривание,
							обеззараживание
						</li>
					</ul>
				</div>
			</div>
		</div>
	)
}

export default Prices