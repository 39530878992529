import React from 'react'
import './footer.css'

const Footer = () => {
  return (
		<footer>
			<div className='container'>
				<div>
					<h2>ТЕПЛЫЕ ДЕНЬКИ</h2>
					<span>пансионаты для пожилых людей в Москве</span>
					<br />
					<br />
					<p>©2025 Все права защищены</p>
				</div>
				<div className='footer-box' id='infos'>
					<p className='footer-header'>Информация</p>
					<span>Политика конфиденциальности</span>
					<span>Фото</span>
					<span>Отзывы</span>
				</div>
				<div className='footer-box'>
					<p className='footer-header'>Контакты</p>
					<span>+7(800) 755-77-57</span>
					<span>teplyedenkisupp@gmail.com</span>
					<span>
						3-й Павловский переулок, д. 14, Москва, 115093 , ООО Салуско
					</span>
				</div>
			</div>
		</footer>
	)
}

export default Footer