import React, { useEffect, useRef, useMemo } from 'react'
import { Link } from 'react-router-dom'

const Map = () => {
	const mapRef = useRef(null)
	const mapInstance = useRef(null) // Ссылка на экземпляр карты, чтобы избежать дублирования

	const points = useMemo(
		() => [
			{
				id: 1,
				coords: [55.945332, 37.286543],
				hint: 'Дом престарелых «Сходня»',
			},
			{
				id: 2,
				coords: [55.572034, 37.760557],
				hint: 'Пансионат «Домодедовская»',
			},
			{
				id: 3,
				coords: [55.746983, 37.188627],
				hint: 'Пансионат на Ильинском шоссе',
			},
			{
				id: 4,
				coords: [56.001182, 37.706595],
				hint: 'Пансионат для престарелых «Мытищи»',
			},
			{
				id: 5,
				coords: [55.930468, 37.650765],
				hint: 'Пансионат для пожилых «Ховрино»',
			},
			{
				id: 6,
				coords: [55.521501, 37.370895],
				hint: 'Пансионат для пожилых «Теплый стан»',
			},
			{ id: 7, coords: [55.980195, 37.730805], hint: 'Пансионат в Медведково' },
			{
				id: 8,
				coords: [55.811174, 36.983928],
				hint: 'Дом престарелых «Щукинская»',
			},
			{
				id: 9,
				coords: [55.724849, 37.161677],
				hint: 'Пансионат Академия долголетия',
			},
			{
				id: 10,
				coords: [55.430079, 37.760952],
				hint: 'Пансионат для пожилых Эдем',
			},
			{
				id: 11,
				coords: [55.930514, 37.859372],
				hint: 'ул. Станционная, 20, Королёв, Московская обл., 141060',
			},
			{
				id: 12,
				coords: [55.859953, 37.120445],
				hint: 'Пансионат для пожилых "Рассвет" Дедовск',
			},
			{
				id: 13,
				coords: [55.909968, 37.736743],
				hint: 'Пансионат Забота Г.Мытищи',
			},
			{
				id: 14,
				coords: [55.478118, 38.208511],
				hint: 'Пансионат Толмачево .Раменский район',
			},
			{
				id: 15,
				coords: [56.135, 36.802],
				hint: "Семейный парк-отель 'Союз Истра'",
			},
			{
				id: 16,
				coords: [55.95, 37.55],
				hint: "Оздоровительный комплекс 'Клязьма'",
			},
			{
				id: 17,
				coords: [55.722, 37.054],
				hint: "Пансионат 'Лесные Дали'",
			},
			{
				id: 18,
				coords: [55.64, 37.21],
				hint: "Пансионат 'Заря'",
			},
			{
				id: 19,
				coords: [55.393, 37.184],
				hint: "Санаторий 'АМАКС Курорт Красная Пахра'",
			},
			{
				id: 20,
				coords: [55.619, 37.034],
				hint: "Пансионат 'Поляны'",
			},
			{
				id: 21,
				coords: [55.948, 37.576],
				hint: "Пансионат 'Клязьминское водохранилище'",
			},
			{
				id: 22,
				coords: [55.732, 37.278],
				hint: "Санаторий 'Барвиха'",
			},
			{
				id: 23,
				coords: [55.734, 36.856],
				hint: "Санаторий 'Звенигород' им. И.М. Сеченова",
			},
			{
				id: 24,
				coords: [55.507, 36.442],
				hint: "Санаторий 'Дорохово'",
			},
			{
				id: 25,
				coords: [55.478118, 38.208511],
				hint: 'Пансионат Толмачево, Раменский район',
			},
			{
				id: 26,
				coords: [55.377905, 37.772801],
				hint: 'Посёлок санатория Подмосковье',
			},
			{
				id: 27,
				coords: [55.723952, 36.967605],
				hint: "Пансионат 'Лесные Дали'",
			},
			{
				id: 28,
				coords: [55.9177, 38.1181],
				hint: 'Санаторий им. Горького',
			},
			{
				id: 29,
				coords: [55.9231, 38.234463],
				hint: "Пансионат 'Дружба'",
			},
			{
				id: 30,
				coords: [55.732, 37.278],
				hint: "Санаторий 'Барвиха'",
			},
			{
				id: 31,
				coords: [55.948, 37.576],
				hint: "Пансионат 'Клязьминское водохранилище'",
			},
			{
				id: 32,
				coords: [55.734, 36.856],
				hint: "Санаторий 'Звенигород' им. И.М. Сеченова",
			},
			{
				id: 33,
				coords: [55.507, 36.442],
				hint: "Санаторий 'Дорохово'",
			},
			{
				id: 34,
				coords: [55.478118, 38.208511],
				hint: 'Пансионат Толмачево, Раменский район',
			},
			{
				id: 35,
				coords: [55.377905, 37.772801],
				hint: 'Посёлок санатория Подмосковье',
			},
			{
				id: 36,
				coords: [55.723952, 36.967605],
				hint: "Пансионат 'Лесные Дали'",
			},
			{
				id: 37,
				coords: [55.9177, 38.1181],
				hint: 'Санаторий им. Горького',
			},
			{
				id: 38,
				coords: [55.9231, 38.234463],
				hint: "Пансионат 'Дружба'",
			},
			{
				id: 39,
				coords: [55.732, 37.278],
				hint: "Санаторий 'Барвиха'",
			},
			{
				id: 40,
				coords: [55.948, 37.576],
				hint: "Пансионат 'Клязьминское водохранилище'",
			},
			{
				id: 41,
				coords: [55.734, 36.856],
				hint: "Санаторий 'Звенигород' им. И.М. Сеченова",
			},
			{
				id: 42,
				coords: [55.507, 36.442],
				hint: "Санаторий 'Дорохово'",
			},
			{
				id: 43,
				coords: [55.478118, 38.208511],
				hint: 'Пансионат Толмачево, Раменский район',
			},
			{
				id: 44,
				coords: [55.377905, 37.772801],
				hint: 'Посёлок санатория Подмосковье',
			},
			{
				id: 45,
				coords: [55.723952, 36.967605],
				hint: "Пансионат 'Лесные Дали'",
			},
			{
				id: 46,
				coords: [55.9177, 38.1181],
				hint: 'Санаторий им. Горького',
			},
			{
				id: 47,
				coords: [55.9231, 38.234463],
				hint: "Пансионат 'Дружба'",
			},
			{
				id: 48,
				coords: [55.732, 37.278],
				hint: "Санаторий 'Барвиха'",
			},
			{
				id: 49,
				coords: [55.948, 37.576],
				hint: "Пансионат 'Клязьминское водохранилище'",
			},
			{
				id: 50,
				coords: [55.734, 36.856],
				hint: "Санаторий 'Звенигород' им. И.М. Сеченова",
			},
			{
				id: 51,
				coords: [55.644, 37.356],
				hint: "Пансионат 'Лесная Поляна'",
			},
			{
				id: 52,
				coords: [55.844, 37.754],
				hint: "Пансионат 'Берёзки'",
			},
			{
				id: 53,
				coords: [55.534, 36.582],
				hint: "Санаторий 'Русский Север'",
			},
			{
				id: 54,
				coords: [55.623, 37.417],
				hint: "Санаторий 'Зеленая Роща'",
			},
			{
				id: 55,
				coords: [55.832, 37.432],
				hint: "Пансионат 'Красная Гора'",
			},
			{
				id: 56,
				coords: [55.879, 37.612],
				hint: "Пансионат 'Калужские Воды'",
			},
			{
				id: 57,
				coords: [55.764, 37.693],
				hint: "Санаторий 'Мечта'",
			},
			{
				id: 58,
				coords: [55.736, 37.618],
				hint: "Пансионат 'Медвежьи Озёра'",
			},
			{
				id: 59,
				coords: [55.644, 37.236],
				hint: "Санаторий 'Истра'",
			},
			{
				id: 60,
				coords: [55.834, 37.654],
				hint: "Пансионат 'Снегири'",
			},
			{
				id: 61,
				coords: [55.437, 37.765],
				hint: "Пансионат 'Зеленый Луг'",
			},
			{
				id: 62,
				coords: [55.555, 37.493],
				hint: "Санаторий 'Лесная Роща'",
			},
			{
				id: 63,
				coords: [55.723, 37.256],
				hint: "Пансионат 'Калужский Остров'",
			},
			{
				id: 64,
				coords: [55.788, 37.619],
				hint: "Пансионат 'Борова'",
			},
			{
				id: 65,
				coords: [55.878, 37.459],
				hint: "Пансионат 'Горки'",
			},
			{
				id: 66,
				coords: [55.907, 37.552],
				hint: "Пансионат 'Московский'",
			},
			{
				id: 67,
				coords: [55.681, 37.725],
				hint: "Санаторий 'Лесной'",
			},
			{
				id: 68,
				coords: [55.672, 37.612],
				hint: "Пансионат 'Долина'",
			},
			{
				id: 69,
				coords: [55.893, 37.589],
				hint: "Санаторий 'Солнечный'",
			},
			{
				id: 70,
				coords: [55.694, 37.493],
				hint: "Пансионат 'Радуга'",
			},
			{
				id: 71,
				coords: [55.832, 37.618],
				hint: "Пансионат 'Вишневый Сад'",
			},
			{
				id: 72,
				coords: [55.908, 37.52],
				hint: "Пансионат 'Печоры'",
			},
			{
				id: 73,
				coords: [55.739, 37.403],
				hint: "Санаторий 'Орехово'",
			},
			{
				id: 74,
				coords: [55.674, 37.528],
				hint: "Пансионат 'Таврида'",
			},
			{
				id: 75,
				coords: [55.491, 37.447],
				hint: "Пансионат 'Альбатрос'",
			},
			{
				id: 76,
				coords: [55.826, 37.477],
				hint: "Пансионат 'Лазурный'",
			},
			{
				id: 77,
				coords: [55.545, 37.738],
				hint: "Санаторий 'Лесные Ключи'",
			},
			{
				id: 78,
				coords: [55.537, 37.41],
				hint: "Пансионат 'Московская Ривьера'",
			},
			{
				id: 79,
				coords: [55.906, 37.428],
				hint: "Пансионат 'Березовая Роща'",
			},
			{
				id: 80,
				coords: [55.741, 37.314],
				hint: "Санаторий 'Зеленая Река'",
			},
			{
				id: 81,
				coords: [55.765, 37.302],
				hint: "Пансионат 'Энергия'",
			},
			{
				id: 82,
				coords: [55.678, 37.232],
				hint: "Пансионат 'Боровое'",
			},
			{
				id: 83,
				coords: [55.535, 37.226],
				hint: "Санаторий 'Мещера'",
			},
			{
				id: 84,
				coords: [55.822, 37.671],
				hint: "Пансионат 'Поляна'",
			},
			{
				id: 85,
				coords: [55.66, 37.571],
				hint: "Пансионат 'Кленовый Лес'",
			},
			{
				id: 86,
				coords: [55.498, 37.309],
				hint: "Пансионат 'Вечерний'",
			},
			{
				id: 87,
				coords: [55.683, 37.772],
				hint: "Санаторий 'Лесной Остров'",
			},
			{
				id: 88,
				coords: [55.596, 37.781],
				hint: "Пансионат 'Голубая Река'",
			},
			{
				id: 89,
				coords: [55.444, 37.568],
				hint: "Пансионат 'Звезды Подмосковья'",
			},
			{
				id: 90,
				coords: [55.478, 37.322],
				hint: "Санаторий 'Тихий берег'",
			},
			{
        id: 91,
        coords: [55.497, 37.708],
        hint: "Пансионат 'Воскресенское'"
    },
    {
        id: 92,
        coords: [55.654, 37.487],
        hint: "Пансионат 'Рублево'"
    },
    {
        id: 93,
        coords: [55.878, 37.491],
        hint: "Санаторий 'Шишкин Лес'"
    },
    {
        id: 94,
        coords: [55.891, 37.575],
        hint: "Пансионат 'Крылья'"
    },
    {
        id: 95,
        coords: [55.725, 37.463],
        hint: "Санаторий 'Лесные Горы'"
    },
    {
        id: 96,
        coords: [55.842, 37.556],
        hint: "Пансионат 'Солнечный'"
    },
    {
        id: 97,
        coords: [55.604, 37.315],
        hint: "Санаторий 'Виктория'"
    },
    {
        id: 98,
        coords: [55.654, 37.674],
        hint: "Пансионат 'Озеро Ландыш'"
    },
    {
        id: 99,
        coords: [55.452, 37.640],
        hint: "Пансионат 'Лазурное'"
    },
    {
        id: 100,
        coords: [55.773, 37.551],
        hint: "Санаторий 'Родник'"
    },
    {
        id: 101,
        coords: [55.621, 37.420],
        hint: "Пансионат 'Тихий уголок'"
    },
    {
        id: 102,
        coords: [55.779, 37.603],
        hint: "Пансионат 'Зеленый берег'"
    },
    {
        id: 103,
        coords: [55.539, 37.252],
        hint: "Санаторий 'Люберцы'"
    },
    {
        id: 104,
        coords: [55.631, 37.765],
        hint: "Пансионат 'Покров'"
    },
    {
        id: 105,
        coords: [55.784, 37.524],
        hint: "Санаторий 'Звезда'"
    },
    {
        id: 106,
        coords: [55.889, 37.641],
        hint: "Пансионат 'Светлый путь'"
    },
    {
        id: 107,
        coords: [55.522, 37.412],
        hint: "Пансионат 'Зеленая долина'"
    },
    {
        id: 108,
        coords: [55.823, 37.749],
        hint: "Санаторий 'Надежда'"
    },
    {
        id: 109,
        coords: [55.603, 37.696],
        hint: "Пансионат 'Шумья'"
    },
    {
        id: 110,
        coords: [55.449, 37.586],
        hint: "Санаторий 'Лесной край'"
    },
	    {
        id: 111,
        coords: [55.451, 37.587],
        hint: "Пансионат 'Жемчужина Подмосковья'"
    },
    {
        id: 112,
        coords: [55.662, 37.439],
        hint: "Пансионат 'Дубровка'"
    },
    {
        id: 113,
        coords: [55.730, 37.342],
        hint: "Санаторий 'Сосновый Бор'"
    },
    {
        id: 114,
        coords: [55.552, 37.665],
        hint: "Пансионат 'Николаевская Роща'"
    },
    {
        id: 115,
        coords: [55.868, 37.543],
        hint: "Пансионат 'Горячий ключ'"
    },
    {
        id: 116,
        coords: [55.716, 37.413],
        hint: "Санаторий 'Тимирязевский'"
    },
    {
        id: 117,
        coords: [55.539, 37.223],
        hint: "Пансионат 'Старая Речка'"
    },
    {
        id: 118,
        coords: [55.812, 37.502],
        hint: "Пансионат 'Барвиха'"
    },
    {
        id: 119,
        coords: [55.621, 37.786],
        hint: "Санаторий 'Подмосковье'"
    },
    {
        id: 120,
        coords: [55.801, 37.406],
        hint: "Пансионат 'Ильинка'"
    },
    {
        id: 121,
        coords: [55.788, 37.525],
        hint: "Пансионат 'Голубое озеро'"
    },
    {
        id: 122,
        coords: [55.438, 37.562],
        hint: "Пансионат 'Лесной мир'"
    },
    {
        id: 123,
        coords: [55.733, 37.474],
        hint: "Санаторий 'Маяк'"
    },
    {
        id: 124,
        coords: [55.694, 37.643],
        hint: "Пансионат 'Рубеж'"
    },
    {
        id: 125,
        coords: [55.758, 37.507],
        hint: "Санаторий 'Вороново'"
    },
    {
        id: 126,
        coords: [55.681, 37.581],
        hint: "Пансионат 'Агропром'"
    },
    {
        id: 127,
        coords: [55.874, 37.602],
        hint: "Пансионат 'Русский Лес'"
    },
    {
        id: 128,
        coords: [55.472, 37.645],
        hint: "Санаторий 'Шатилово'"
    },
    {
        id: 129,
        coords: [55.694, 37.392],
        hint: "Пансионат 'Радуга'"
    },
    {
        id: 130,
        coords: [55.633, 37.321],
        hint: "Пансионат 'Зеленая долина'"
    },
    {
        id: 131,
        coords: [55.823, 37.465],
        hint: "Санаторий 'Барышево'"
    },
    {
        id: 132,
        coords: [55.642, 37.592],
        hint: "Пансионат 'Завидово'"
    },
    {
        id: 133,
        coords: [55.803, 37.454],
        hint: "Санаторий 'Теплое озеро'"
    },
    {
        id: 134,
        coords: [55.733, 37.491],
        hint: "Пансионат 'Лесной уголок'"
    },
    {
        id: 135,
        coords: [55.545, 37.732],
        hint: "Санаторий 'Атмосфера'"
    },
    {
        id: 136,
        coords: [55.649, 37.413],
        hint: "Пансионат 'Дубрава'"
    },
    {
        id: 137,
        coords: [55.764, 37.524],
        hint: "Пансионат 'Гармония'"
    },
    {
        id: 138,
        coords: [55.670, 37.602],
        hint: "Санаторий 'Тимошкино'"
    },
    {
        id: 139,
        coords: [55.624, 37.287],
        hint: "Пансионат 'Джаз'"
    },
    {
        id: 140,
        coords: [55.547, 37.640],
        hint: "Пансионат 'Весна'"
    },
	  {
        id: 141,
        coords: [55.703, 37.631],
        hint: "Пансионат 'Мечта на природе'"
    },
    {
        id: 142,
        coords: [55.853, 37.743],
        hint: "Санаторий 'Остров счастья'"
    },
    {
        id: 143,
        coords: [55.753, 37.624],
        hint: "Пансионат 'Владимирка'"
    },
    {
        id: 144,
        coords: [55.732, 37.275],
        hint: "Пансионат 'Заря'"
    },
    {
        id: 145,
        coords: [55.713, 37.482],
        hint: "Пансионат 'Лесной край'"
    },
    {
        id: 146,
        coords: [55.593, 37.563],
        hint: "Санаторий 'Сосновый луг'"
    },
    {
        id: 147,
        coords: [55.812, 37.418],
        hint: "Пансионат 'Березовая роща'"
    },
    {
        id: 148,
        coords: [55.635, 37.426],
        hint: "Санаторий 'Пущино'"
    },
    {
        id: 149,
        coords: [55.745, 37.548],
        hint: "Пансионат 'Ильинский Парк'"
    },
    {
        id: 150,
        coords: [55.507, 37.436],
        hint: "Пансионат 'Московский Сад'"
    },
    {
        id: 151,
        coords: [55.691, 37.612],
        hint: "Санаторий 'Ершово'"
    },
    {
        id: 152,
        coords: [55.632, 37.697],
        hint: "Пансионат 'Крещатик'"
    },
    {
        id: 153,
        coords: [55.825, 37.524],
        hint: "Пансионат 'Черничный'"
    },
    {
        id: 154,
        coords: [55.749, 37.358],
        hint: "Пансионат 'Золотая долина'"
    },
    {
        id: 155,
        coords: [55.533, 37.474],
        hint: "Санаторий 'Солнечные просторы'"
    },
    {
        id: 156,
        coords: [55.781, 37.383],
        hint: "Пансионат 'Дубровская роща'"
    },
    {
        id: 157,
        coords: [55.542, 37.393],
        hint: "Пансионат 'Золотые пески'"
    },
    {
        id: 158,
        coords: [55.774, 37.579],
        hint: "Санаторий 'Котельники'"
    },
    {
        id: 159,
        coords: [55.865, 37.618],
        hint: "Пансионат 'Гора'"
    },
    {
        id: 160,
        coords: [55.834, 37.476],
        hint: "Пансионат 'Скворечник'"
    },
    {
        id: 161,
        coords: [55.496, 37.594],
        hint: "Пансионат 'Семёново'"
    },
    {
        id: 162,
        coords: [55.756, 37.319],
        hint: "Санаторий 'Волга'"
    },
    {
        id: 163,
        coords: [55.638, 37.438],
        hint: "Пансионат 'Широкий Луг'"
    },
    {
        id: 164,
        coords: [55.893, 37.759],
        hint: "Пансионат 'Александровка'"
    },
    {
        id: 165,
        coords: [55.835, 37.411],
        hint: "Санаторий 'Речка'"
    },
    {
        id: 166,
        coords: [55.741, 37.697],
        hint: "Пансионат 'Сказочный лес'"
    },
    {
        id: 167,
        coords: [55.876, 37.597],
        hint: "Пансионат 'Таганский'"
    },
    {
        id: 168,
        coords: [55.530, 37.724],
        hint: "Санаторий 'Тюменка'"
    },
    {
        id: 169,
        coords: [55.843, 37.420],
        hint: "Пансионат 'Лесной дом'"
    },
    {
        id: 170,
        coords: [55.795, 37.342],
        hint: "Пансионат 'Беркут'"
    },
	    {
        id: 171,
        coords: [55.845, 37.453],
        hint: "Пансионат 'Шатуры'"
    },
    {
        id: 172,
        coords: [55.924, 37.575],
        hint: "Санаторий 'Маяк'"
    },
    {
        id: 173,
        coords: [55.504, 37.380],
        hint: "Пансионат 'Лесной'"
    },
    {
        id: 174,
        coords: [55.755, 37.552],
        hint: "Санаторий 'Горки'"
    },
    {
        id: 175,
        coords: [55.451, 37.319],
        hint: "Пансионат 'Шоколад'"
    },
    {
        id: 176,
        coords: [55.861, 37.647],
        hint: "Пансионат 'Веснянка'"
    },
    {
        id: 177,
        coords: [55.492, 37.545],
        hint: "Пансионат 'Тихая бухта'"
    },
    {
        id: 178,
        coords: [55.802, 37.479],
        hint: "Санаторий 'Теплый ручей'"
    },
    {
        id: 179,
        coords: [55.731, 37.539],
        hint: "Пансионат 'Элегия'"
    },
    {
        id: 180,
        coords: [55.484, 37.432],
        hint: "Пансионат 'Гроза'"
    },
    {
        id: 181,
        coords: [55.758, 37.287],
        hint: "Пансионат 'Зеленая долина'"
    },
    {
        id: 182,
        coords: [55.561, 37.318],
        hint: "Пансионат 'Родник'"
    },
    {
        id: 183,
        coords: [55.671, 37.503],
        hint: "Пансионат 'Зеленая роща'"
    },
    {
        id: 184,
        coords: [55.504, 37.532],
        hint: "Пансионат 'Северный'"
    },
    {
        id: 185,
        coords: [55.542, 37.496],
        hint: "Санаторий 'Кедр'"
    },
    {
        id: 186,
        coords: [55.715, 37.651],
        hint: "Пансионат 'Радуга'"
    },
    {
        id: 187,
        coords: [55.804, 37.530],
        hint: "Пансионат 'Белый Лев'"
    },
    {
        id: 188,
        coords: [55.657, 37.278],
        hint: "Санаторий 'Чистые пруды'"
    },
    {
        id: 189,
        coords: [55.844, 37.363],
        hint: "Пансионат 'Чистое озеро'"
    },
    {
        id: 190,
        coords: [55.652, 37.348],
        hint: "Пансионат 'Звезды Подмосковья'"
    },
    {
        id: 191,
        coords: [55.843, 37.501],
        hint: "Санаторий 'Ретро'"
    },
    {
        id: 192,
        coords: [55.623, 37.455],
        hint: "Пансионат 'Классик'"
    },
    {
        id: 193,
        coords: [55.536, 37.289],
        hint: "Пансионат 'Заречье'"
    },
    {
        id: 194,
        coords: [55.531, 37.612],
        hint: "Санаторий 'Лесная полянка'"
    },
    {
        id: 195,
        coords: [55.832, 37.482],
        hint: "Пансионат 'Тихий вечер'"
    },
    {
        id: 196,
        coords: [55.477, 37.586],
        hint: "Пансионат 'Южное сияние'"
    },
    {
        id: 197,
        coords: [55.733, 37.659],
        hint: "Санаторий 'Речка'"
    },
    {
        id: 198,
        coords: [55.635, 37.524],
        hint: "Пансионат 'Зеленая долина'"
    },
    {
        id: 199,
        coords: [55.574, 37.585],
        hint: "Санаторий 'Ильичевск'"
    },
    {
        id: 200,
        coords: [55.873, 37.674],
        hint: "Пансионат 'Гроза'"
    },
    {
        id: 201,
        coords: [55.510, 37.424],
        hint: "Пансионат 'Венера'"
    },
    {
        id: 202,
        coords: [55.555, 37.376],
        hint: "Пансионат 'Светлый путь'"
    },
    {
        id: 203,
        coords: [55.688, 37.552],
        hint: "Санаторий 'Звезда'"
    },
    {
        id: 204,
        coords: [55.537, 37.582],
        hint: "Пансионат 'Маяк'"
    },
    {
        id: 205,
        coords: [55.702, 37.359],
        hint: "Пансионат 'Гармония'"
    },
    {
        id: 206,
        coords: [55.743, 37.323],
        hint: "Санаторий 'Парус'"
    },
    {
        id: 207,
        coords: [55.624, 37.302],
        hint: "Пансионат 'Теплый берег'"
    },
    {
        id: 208,
        coords: [55.786, 37.596],
        hint: "Пансионат 'Восток'"
    },
    {
        id: 209,
        coords: [55.833, 37.375],
        hint: "Пансионат 'Лагуна'"
    },
    {
        id: 210,
        coords: [55.779, 37.324],
        hint: "Санаторий 'Сибирь'"
    }
		],
		[]
	)

	useEffect(() => {
		// Функция для загрузки карты
		const loadYandexMap = () => {
			if (window.ymaps && !mapInstance.current) {
				window.ymaps.ready(() => {
					// Если карта уже создана, уничтожаем её
					if (mapInstance.current) {
						mapInstance.current.destroy()
					}

					// Создаем карту
					mapInstance.current = new window.ymaps.Map(mapRef.current, {
						center: [55.751574, 37.573856],
						zoom: 8.5,
					})

					// Добавляем маркеры
					points.forEach(point => {
						const placemark = new window.ymaps.Placemark(point.coords, {
							hintContent: 'Пансионат "Теплые Деньки"',
						})
						mapInstance.current.geoObjects.add(placemark)
					})
				})
			}
		}

		// Проверяем, если скрипт еще не добавлен, добавляем его
		if (
			!document.querySelector(
				'script[src="https://api-maps.yandex.ru/2.1/?lang=ru_RU"]'
			)
		) {
			const script = document.createElement('script')
			script.src = 'https://api-maps.yandex.ru/2.1/?lang=ru_RU'
			script.onload = loadYandexMap
			script.onerror = () => {
				console.error('Failed to load Yandex Maps API')
			}
			document.body.appendChild(script)
		} else {
			loadYandexMap()
		}

		// Очистка карты при размонтировании компонента
		return () => {
			if (mapInstance.current) {
				mapInstance.current.destroy()
				mapInstance.current = null
			}
		}
	}, [points])

	return (
		<div>
			{/* Контейнер для карты */}
			<div
				className='ymaps'
				ref={mapRef}
				style={{ width: '100%', height: '550px', borderRadius: '20px' }}
			/>
		</div>
	)
}

export default Map
